import React, { useContext, useRef, useEffect, useState } from "react"
import propTypes from "prop-types"
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  Autocomplete,
  TextareaAutosize,
  ToggleButton,
  Tooltip,
  CircularProgress,
  ToggleButtonGroup,
  Badge,
  unstable_useEnhancedEffect,
} from "@mui/material"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import PacoSendImage from "assets/images/budgeting/Download.png"
import { useParams } from "react-router-dom"
import { isNil } from "lodash"
import Select from "react-select"

import { Switch } from "@mui/material"
import styled from "@mui/material/styles/styled"
import i18n from "i18n"
import ProjectContext from "context/projects/projectContext"
import AuthContext from "context/auth/authContext"
import DownloadBlockModal from "./DownloadBlockModal"
import Budget2023Context from "context/budget2023/Budget2023Context"
import ModalCallError from "common/modalCallError"
import ModalOpenDownload from "common/ModalOpenDownload"
import axios from "axios"
import * as amplitude from "@amplitude/analytics-browser"
import DownloadBudgetEnum from "common/DownloadBudgetEnum"
import CertHistoryContext from "context/certHistory/CertHistoryContext"

import ModalWaitProcess from "common/ModalWaitProcess"
import useDownloadBudgetConfig, {
  DOWNLOAD_TYPES,
  INPUTS,
} from "hooks/budgeting/useDownloadBudgetConfig"

/*const YellowSwitch = styled(Switch)(({ theme }) => ({
	  '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#d2ac10',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#F0CB38',
    },
}));*/

const SendModal = {
  position: "absolute",
  top: "0%",
  right: "0%",
  height: "100%",
  maxWidth: "425px",
  backgroundColor: "white",
  paddingRight: "0px",
  borderRadius: "16px 0px 0px 16px",
  overflowY: "scroll",
  overflowX: "hidden",
}

const DownloadBudget = ({
  open,
  setOpen,
  name,
  soyFinal,
  fromTab = DownloadBudgetEnum.DEFAULT,
}) => {
  const { projectId } = useParams()
  const { projectInfo, getProject } = useContext(ProjectContext)
  const { exportSideBar } = useContext(CertHistoryContext)
  const { downloadBudget, urlDownload, pdfDownload, downloadCertification } =
    useContext(Budget2023Context)
  const [downloadBudgetConfig, setDownloadBudgetConfig] =
    useDownloadBudgetConfig()
  const [typeBudget, setTypeBudget] = React.useState(soyFinal ? "PF" : "PC")
  const { user, updateUser, getUser } = useContext(AuthContext)
  const [tipo, setTipo] = React.useState(DOWNLOAD_TYPES.EXCEL)
  const [brief, setBrief] = useState("bottom")
  const [openDownload, setOpenDownload] = useState(false)
  const [selected, setSelected] = useState(false)

  const [openModalWaitProcess, setOpenModalWaitProcess] = useState(false)

  const changeBrief = e => {
    if (e.target.value == "top") {
      setBrief("top")
    } else {
      setBrief("bottom")
    }
  }

  useEffect(() => {
    if (!projectInfo) {
      getProject(projectId)
    }
    if (!user) {
      getUser()
    }
  }, [])

  const handleChangeTipo = (event, newTipo) => {
    if (newTipo !== null) {
      setTipo(newTipo)
    }
  }

  const [openError, setOpenError] = useState(false)
  const [errorToSend, setErrorToSend] = useState("")
  const [openBlockModal, setOpenBlockModal] = useState(false)
  const [urlState, setUrlState] = useState("")

  const [processState, setProcessState] = useState({})
  // PK="d102df6e-7c57-4155-acc1-6a8f35123c62#68c48ec4-e6a9-406c-bfee-21b5cb235142" SK="4b8fad12-2f64-4ceb-b81b-e0e8babe6c5b"

  const downloadExcelFromCertificationTab = async () => {
    let result = await downloadCertification(projectId, name)
    if (result?.error) {
      setOpenError(true)
      setErrorToSend(result)
    }
  }

  const downloadExcelFromHistoryCertificationTab = async () => {
    let result = await exportSideBar.downloadHistoryCertification(
      projectId,
      name,
      exportSideBar.selectedCertificationId,
    )
    if (result?.error) {
      setOpenError(true)
      setErrorToSend(result)
    }
  }

  const downloadExcelFromDefault = async () => {
    let result = await downloadBudget(projectId, name, tipo, typeBudget, {
      cost: muestraPC,
      price: muestraUser,
      decomposition: muestraDescomposicion,
      envelope: muestrCompany,
      measures: muestraMeasures,
      comment: muestraComentarios,
      description: muestraDescription,
    })
    if (result?.error) {
      setOpenError(true)
      setErrorToSend(result)
    }
  }

  const getOptions = () => {
    return {
      envelope: muestrCompany,
      description: muestraDescription,
      cover: muestraCover,
      briefing: optVersion
        ? briefingPosition
          ? { position: briefingPosition }
          : {}
        : briefingPosition,
      price: optPrice,
      mode: optVersion ? "price" : "legacy",
      decomposition: muestraDescomposicion,
      preview: false,
      conditions: muestraCondiciones,
      signature: muestraFrima,
      header_date: muestraDate,
      currency: "EUR",
      language: "es",
    }
  }

  const triggerExcelDownload = async () => {
    switch (fromTab) {
      case DownloadBudgetEnum.FROM_CERTIFICATION:
        downloadExcelFromCertificationTab()
        break
      case DownloadBudgetEnum.FROM_HISTORY_CERTIFICATION:
        downloadExcelFromHistoryCertificationTab()
        break
      default:
        downloadExcelFromDefault()
        break
    }
  }

  const triggerPdfDownload = async () => {
    const options = getOptions()
    console.log(`options=${JSON.stringify(options)}`)
    switch (options.mode) {
      case "legacy":
        const urlId = await pdfDownload(user, projectId, options)
        if (urlId?.id) {
          setUrlState(urlId.id)
          setOpenDownload(true)
        } else {
          setOpenError(true)
          setErrorToSend({
            error: true,
            message: "Error al descargar pdf",
            function: "/project/pdf/generate/" + projectId,
            service: "service-budget",
          })
        }
        break
      case "price":
        console.log("triggerPdfDownload v2")

        let newOption = options

        if (fromTab === DownloadBudgetEnum.FROM_CERTIFICATION) {
          newOption = {
            briefing: {},
            decomposition: false,
            mode: "certification",
            price: "client",
            certificationId: "",
          }
        }
        const result = await pdfDownload(user, projectId, newOption)

        if (result?.error) {
          setOpenError(true)
          setErrorToSend(result)
        } else {
          setProcessState(result)
          setOpenModalWaitProcess(true)
        }
        break
    }
  }

  const handleClick = async e => {
    e.preventDefault()
    //console.log(`handleClick: fromTab=${fromTab}`)

    if (tipo == "excel") {
      amplitude.track({
        event_type: "Budget Excel Downloaded",
        event_properties: { projectId: projectId, projectName: name },
      })
      await triggerExcelDownload()
    } else if (tipo == "pdf") {
      amplitude.track({
        event_type: "Budget PDF Downloaded",
        event_properties: { projectId: projectId, projectName: name },
      })
      await triggerPdfDownload()
    }
  }

  useEffect(() => {
    if (urlDownload == "done") {
      setOpen(false)
    }
  }, [urlDownload])

  const [muestraP, setMuestraP] = useState(true)
  const [muestraDescomposicion, setMuestraDescomposicion] = useState(
    downloadBudgetConfig[tipo][INPUTS.PART_DECOMPOSITION],
  )
  const [muestraCondiciones, setMuestraCondiciones] = useState(
    downloadBudgetConfig[tipo][INPUTS.GENERAL_CONDITIONS],
  )
  const [muestraPC, setMuestraPC] = useState(
    downloadBudgetConfig[tipo][INPUTS.COST_PRICE],
  )
  const [muestraUser, setMuestraUser] = useState(
    downloadBudgetConfig[tipo][INPUTS.CLIENT_PRICE],
  )
  const [muestrCompany, setMuestraCompany] = useState(
    downloadBudgetConfig[tipo][INPUTS.COMPANY_AND_CLIENT_INFO],
  )
  const [muestraCover, setMuestraCover] = useState(
    downloadBudgetConfig[tipo][INPUTS.ADD_COVER],
  )

  const briefingPositionRef = useRef(null)
  const briefingPositionOptions = [
    { value: false, label: i18n.t("Sin resumen") },
    { value: "top", label: i18n.t("Resumen al inicio") },
    { value: "bottom", label: i18n.t("Resumen al final") },
  ]
  const [briefingPosition, setBriefingPosition] = useState(
    downloadBudgetConfig[tipo][INPUTS.BRIEFING_POSITION],
  )

  const [muestraDescription, setMuestraDescription] = useState(true)
  const [muestraFrima, setMuestraFirma] = useState(
    downloadBudgetConfig[tipo][INPUTS.ADD_SIGNATURE],
  )
  const [muestraDate, setMuestraDate] = useState(
    downloadBudgetConfig[tipo][INPUTS.ADD_DATE],
  )
  const [muestraMeasures, setMuestraMeasures] = useState(
    downloadBudgetConfig[tipo][INPUTS.MEASURES],
  )
  const [muestraComentarios, setMuestraComentarios] = useState(
    downloadBudgetConfig[tipo][INPUTS.COMMENTS],
  )
  const [optVersion, setOptVersion] = useState(
    downloadBudgetConfig[tipo][INPUTS.NEW_FORMAT],
  )

  const pdfPriceOptionsRef = useRef(null)
  const pdfPriceOptions = [
    { value: "client", label: i18n.t("Precio cliente") },
    { value: "blind", label: i18n.t("Sin precio") },
    { value: "cost", label: i18n.t("Costo Objetivo") },
    { value: "costReal", label: i18n.t("Costo Real") },
  ]
  const [optPrice, setOptPrice] = useState(
    downloadBudgetConfig[tipo][INPUTS.PDF_PRICE_OPTION],
  )

  useEffect(() => {
    setMuestraDescomposicion(
      downloadBudgetConfig[tipo][INPUTS.PART_DECOMPOSITION],
    )
    setMuestraCondiciones(downloadBudgetConfig[tipo][INPUTS.GENERAL_CONDITIONS])
    setMuestraCompany(
      downloadBudgetConfig[tipo][INPUTS.COMPANY_AND_CLIENT_INFO],
    )
    setBriefingPosition(downloadBudgetConfig[tipo][INPUTS.BRIEFING_POSITION])
    setMuestraCover(downloadBudgetConfig[tipo][INPUTS.ADD_COVER])
    setMuestraFirma(downloadBudgetConfig[tipo][INPUTS.ADD_SIGNATURE])
    setMuestraDate(downloadBudgetConfig[tipo][INPUTS.ADD_DATE])
    setOptVersion(downloadBudgetConfig[tipo][INPUTS.NEW_FORMAT])
  }, [tipo, downloadBudgetConfig])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <Box style={SendModal} component="form">
          <Grid
            container
            direction={"column"}
            marginTop={"0.5rem"}
            spacing={3}
            marginLeft={"-6px"}
            paddingRight={"10px"}
          >
            <Grid item marginLeft={"0rem"}>
              <IconButton
                sx={{
                  border: "1px solid #000001",
                  height: "25px",
                  width: "25px",
                  color: "black",
                  marginBottom: "1rem",
                }}
                onClick={() => {
                  setOpen(false)
                  exportSideBar.setSelectedCertificationId(null)
                }}
              >
                <ArrowForwardIosRoundedIcon style={{ width: "15px" }} />
              </IconButton>
            </Grid>
            <Box style={{ justifyContent: "center", display: "flex" }}>
              <img
                src={PacoSendImage}
                alt="PacoSend"
                width="100px"
                height="150px"
              />
            </Box>
            <Grid item>
              <p
                style={{
                  fontFamily: "Helvetica",
                  fontWeight: "600",
                  marginBottom: "0px",
                  fontSize: "16px",
                  color: "#414141",
                }}
              >
                {i18n.t("Realizarás la descarga del presupuesto de")}
                {name}.
              </p>
            </Grid>

            <Grid item>
              <Typography
                variant="body1"
                align="left"
                style={{
                  fontFamily: "Helvetica",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#414141",
                  marginBottom: "20px",
                }}
              >
                1. {i18n.t("Indica que formato deseas descargar")}
              </Typography>
            </Grid>
            <Box display={"flex"} justifyContent="left" ml="10px">
              <ToggleButtonGroup
                value={tipo}
                onChange={handleChangeTipo}
                exclusive
                sx={{
                  "& .MuiToggleButtonGroup-grouped": {
                    marginX: "1rem",
                    borderRadius: "50px",
                    border: "1px solid #FFD52B",
                    paddingX: "0rem",
                    lineHeight: "15px",
                    fontWeight: "300",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontFamily: "Helvetica",
                    textTransform: "none",
                    width: "76px",
                    color: "#414141",
                    opacity: "100%",
                  },
                  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                    borderRadius: "50px",
                    border: "1px solid #FFD52B",
                  },
                  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                    borderRadius: "50px",
                    border: "1px solid #FFD52B",
                  },
                  "& .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                    {
                      backgroundColor: "#FFD52B",
                      opacity: "100%",
                    },
                }}
              >
                <ToggleButton value={DOWNLOAD_TYPES.EXCEL}>Excel</ToggleButton>
                {fromTab === DownloadBudgetEnum.FROM_HISTORY_CERTIFICATION ? (
                  <Tooltip title="Próximamente">
                    <span>
                      <ToggleButton
                        value={DOWNLOAD_TYPES.PDF}
                        selected={selected}
                        onChange={() => setSelected(!selected)}
                        style={{
                          pointerEvents: selected ? "auto" : "none",
                          opacity: selected ? 1 : 0.5,
                        }}
                      >
                        PDF
                      </ToggleButton>
                    </span>
                  </Tooltip>
                ) : (
                  <ToggleButton value="pdf">PDF</ToggleButton>
                )}
              </ToggleButtonGroup>
            </Box>
            <Grid item>
              <Typography
                variant="body1"
                align="left"
                style={{
                  fontFamily: "Helvetica",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#414141",
                  marginBottom: "20px",
                }}
              >
                2. {i18n.t("Indica los campos que deseas que sean mostrados")}
              </Typography>
            </Grid>
            {fromTab === DownloadBudgetEnum.DEFAULT && (
              <Box display={"column"} justifyContent="left" ml="25px">
                {tipo == "excel" && (
                  <>
                    <div
                      id="Poppinsnormal"
                      style={{
                        width: "260px",
                        marginBottom: "10px",
                        position: "relative",
                        marginLeft: "0px",
                        height: "30px",
                        backgroundColor: "#F3F3F3",
                        borderRadius: "6px",
                        paddingTop: "-3px",
                        display: "flex",
                        paddingTop: "5px",
                        marginRight: "40px",
                        paddingLeft: "15px",
                      }}
                    >
                      {i18n.t("Precio Cliente")}
                      <div style={{ position: "absolute", right: "10px" }}>
                        <Switch
                          className="aaa"
                          checked={muestraUser}
                          onClick={() => {
                            setMuestraUser(!muestraUser)
                            setDownloadBudgetConfig(
                              tipo,
                              INPUTS.CLIENT_PRICE,
                              !muestraUser,
                            )
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      </div>
                    </div>
                  </>
                )}

                {tipo == "pdf" && (
                  <div
                    style={{
                      width: "260px",
                      marginBottom: "10px",
                      position: "relative",
                      marginLeft: "0px",
                      height: "46px",
                      backgroundColor: "#F3F3F3",
                      display: "block",
                      borderRadius: "6px",
                      paddingTop: "-3px",
                      paddingTop: "5px",
                      paddingLeft: "4px",
                    }}
                  >
                    <Select
                      ref={pdfPriceOptionsRef}
                      options={pdfPriceOptions}
                      styles={{
                        control: base => ({
                          ...base,
                          height: "30px",
                          width: "100%",
                          backgroundColor: "#F3F3F3",
                          borderRadius: "6px",
                          border: "none",
                          boxShadow: "none",
                          cursor: "pointer",
                          fontSize: "13px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }),
                      }}
                      value={
                        optPrice
                          ? pdfPriceOptions.find(o => o.value === optPrice)
                          : pdfPriceOptions[0]
                      }
                      onChange={e => {
                        setOptPrice(e.value)
                        setDownloadBudgetConfig(
                          tipo,
                          INPUTS.PDF_PRICE_OPTION,
                          e.value,
                        )
                        pdfPriceOptionsRef.current.blur()
                      }}
                    />
                  </div>
                )}
                {tipo == "excel" && (
                  <div
                    id="Poppinsnormal"
                    style={{
                      width: "260px",
                      marginBottom: "10px",
                      position: "relative",
                      marginLeft: "0px",
                      height: "30px",
                      backgroundColor: "#F3F3F3",
                      borderRadius: "6px",
                      paddingTop: "-3px",
                      display: "flex",
                      paddingTop: "5px",
                      marginRight: "40px",
                      paddingLeft: "15px",
                    }}
                  >
                    {i18n.t("Precio Coste")}
                    <div style={{ position: "absolute", right: "10px" }}>
                      <Switch
                        className="aaa"
                        checked={muestraPC}
                        onClick={() => {
                          setMuestraPC(!muestraPC)
                          setDownloadBudgetConfig(
                            tipo,
                            INPUTS.COST_PRICE,
                            !muestraPC,
                          )
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        color="warning"
                      />
                    </div>
                  </div>
                )}
                {tipo == "pdf" && (
                  <div
                    id="Poppinsnormal"
                    style={{
                      width: "260px",
                      marginBottom: "10px",
                      position: "relative",
                      marginLeft: "0px",
                      height: "30px",
                      backgroundColor: "#F3F3F3",
                      borderRadius: "6px",
                      paddingTop: "-3px",
                      display: "flex",
                      paddingTop: "5px",
                      paddingLeft: "15px",
                    }}
                  >
                    {i18n.t("Info. de empresa y cliente")}
                    <div style={{ position: "absolute", right: "10px" }}>
                      <Switch
                        className="aaa"
                        checked={muestrCompany}
                        onClick={() => {
                          setMuestraCompany(!muestrCompany)
                          setDownloadBudgetConfig(
                            tipo,
                            INPUTS.COMPANY_AND_CLIENT_INFO,
                            !muestrCompany,
                          )
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        color="warning"
                      />
                    </div>
                  </div>
                )}
                {tipo == "pdf" && (
                  <div
                    id="Poppinsnormal"
                    style={{
                      width: "260px",
                      marginBottom: "10px",
                      position: "relative",
                      marginLeft: "0px",
                      height: "30px",
                      backgroundColor: "#F3F3F3",
                      borderRadius: "6px",
                      paddingTop: "-3px",
                      display: "flex",
                      paddingTop: "5px",
                      paddingLeft: "15px",
                    }}
                  >
                    {i18n.t("Condiciones generales")}
                    <div style={{ position: "absolute", right: "10px" }}>
                      <Switch
                        className="aaa"
                        checked={muestraCondiciones}
                        onClick={() => {
                          setMuestraCondiciones(!muestraCondiciones)
                          setDownloadBudgetConfig(
                            tipo,
                            INPUTS.GENERAL_CONDITIONS,
                            !muestraCondiciones,
                          )
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        color="warning"
                      />
                    </div>
                  </div>
                )}

                <div
                  id="Poppinsnormal"
                  style={{
                    width: "260px",
                    marginBottom: "10px",
                    position: "relative",
                    marginLeft: "0px",
                    height: "30px",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "6px",
                    paddingTop: "-3px",
                    display: "flex",
                    paddingTop: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  {i18n.t("Descomposición de partida")}
                  <div style={{ position: "absolute", right: "10px" }}>
                    <Switch
                      className="aaa"
                      checked={muestraDescomposicion}
                      onClick={() => {
                        setMuestraDescomposicion(!muestraDescomposicion)
                        setDownloadBudgetConfig(
                          tipo,
                          INPUTS.PART_DECOMPOSITION,
                          !muestraDescomposicion,
                        )
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      color="warning"
                    />
                  </div>
                </div>
                {tipo == "excel" && (
                  <>
                    <div
                      id="Poppinsnormal"
                      style={{
                        width: "260px",
                        marginBottom: "10px",
                        position: "relative",
                        marginLeft: "0px",
                        height: "30px",
                        backgroundColor: "#F3F3F3",
                        borderRadius: "6px",
                        paddingTop: "-3px",
                        display: "flex",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      {i18n.t("Mediciones")}
                      <div style={{ position: "absolute", right: "10px" }}>
                        <Switch
                          className="aaa"
                          checked={muestraMeasures}
                          onClick={() => {
                            setMuestraMeasures(!muestraMeasures)
                            setDownloadBudgetConfig(
                              tipo,
                              INPUTS.MEASURES,
                              !muestraMeasures,
                            )
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      </div>
                    </div>
                    <div
                      id="Poppinsnormal"
                      style={{
                        width: "260px",
                        marginBottom: "10px",
                        position: "relative",
                        marginLeft: "0px",
                        height: "30px",
                        backgroundColor: "#F3F3F3",
                        borderRadius: "6px",
                        paddingTop: "-3px",
                        display: "flex",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      {i18n.t("Comentarios")}
                      <div style={{ position: "absolute", right: "10px" }}>
                        <Switch
                          className="aaa"
                          checked={muestraComentarios}
                          onClick={() => {
                            setMuestraComentarios(!muestraComentarios)
                            setDownloadBudgetConfig(
                              tipo,
                              INPUTS.COMMENTS,
                              !muestraComentarios,
                            )
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      </div>
                    </div>
                  </>
                )}

                {tipo == "pdf" && (
                  <div
                    style={{
                      width: "260px",
                      marginBottom: "10px",
                      position: "relative",
                      marginLeft: "0px",
                      height: "46px",
                      backgroundColor: "#F3F3F3",
                      display: "block",
                      borderRadius: "6px",
                      paddingTop: "-3px",
                      paddingTop: "5px",
                      paddingLeft: "4px",
                    }}
                  >
                    <Select
                      ref={briefingPositionRef}
                      options={briefingPositionOptions}
                      styles={{
                        control: base => ({
                          ...base,
                          height: "30px",
                          width: "100%",
                          backgroundColor: "#F3F3F3",
                          borderRadius: "6px",
                          border: "none",
                          boxShadow: "none",
                          cursor: "pointer",
                          fontSize: "13px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }),
                      }}
                      value={
                        briefingPosition
                          ? briefingPositionOptions.find(
                              o => o.value === briefingPosition,
                            )
                          : briefingPositionOptions[0]
                      }
                      onChange={e => {
                        setBriefingPosition(e.value)
                        setDownloadBudgetConfig(
                          tipo,
                          INPUTS.BRIEFING_POSITION,
                          e.value,
                        )
                        briefingPositionRef.current.blur()
                      }}
                    />
                  </div>
                )}

                {tipo == "pdf" && (
                  <>
                    <div
                      id="Poppinsnormal"
                      style={{
                        width: "260px",
                        marginBottom: "10px",
                        position: "relative",
                        marginLeft: "0px",
                        height: "30px",
                        backgroundColor: "#F3F3F3",
                        borderRadius: "6px",
                        paddingTop: "-3px",
                        display: "flex",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      {i18n.t("Añadir portada")}
                      <div style={{ position: "absolute", right: "10px" }}>
                        <Switch
                          className="aaa"
                          checked={muestraCover}
                          onClick={() => {
                            setMuestraCover(!muestraCover)
                            setDownloadBudgetConfig(
                              tipo,
                              INPUTS.ADD_COVER,
                              !muestraCover,
                            )
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      </div>
                    </div>
                    <div
                      id="Poppinsnormal"
                      style={{
                        width: "260px",
                        marginBottom: "10px",
                        position: "relative",
                        marginLeft: "0px",
                        height: "30px",
                        backgroundColor: "#F3F3F3",
                        borderRadius: "6px",
                        paddingTop: "-3px",
                        display: "flex",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      {i18n.t("Añadir firma")}
                      <div style={{ position: "absolute", right: "10px" }}>
                        <Switch
                          className="aaa"
                          checked={muestraFrima}
                          onClick={() => {
                            setMuestraFirma(!muestraFrima)
                            setDownloadBudgetConfig(
                              tipo,
                              INPUTS.ADD_SIGNATURE,
                              !muestraFrima,
                            )
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      </div>
                    </div>
                    <div
                      id="Poppinsnormal"
                      style={{
                        width: "260px",
                        marginBottom: "10px",
                        position: "relative",
                        marginLeft: "0px",
                        height: "30px",
                        backgroundColor: "#F3F3F3",
                        borderRadius: "6px",
                        paddingTop: "-3px",
                        display: "flex",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      {i18n.t("Añadir fecha")}
                      <div style={{ position: "absolute", right: "10px" }}>
                        <Switch
                          className="aaa"
                          checked={muestraDate}
                          onClick={() => {
                            setMuestraDate(!muestraDate)
                            setDownloadBudgetConfig(
                              tipo,
                              INPUTS.ADD_DATE,
                              !muestraDate,
                            )
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      </div>
                    </div>

                    <div
                      id="Poppinsnormal"
                      style={{
                        width: "260px",
                        marginBottom: "10px",
                        position: "relative",
                        marginLeft: "0px",
                        height: "30px",
                        backgroundColor: "#F3F3F3",
                        borderRadius: "6px",
                        paddingTop: "-3px",
                        display: "flex",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      {i18n.t("Formato nuevo")}
                      <div style={{ position: "absolute", right: "10px" }}>
                        <Switch
                          className="aaa"
                          checked={optVersion}
                          onClick={() => {
                            setOptVersion(!optVersion)
                            setDownloadBudgetConfig(
                              tipo,
                              INPUTS.NEW_FORMAT,
                              !optVersion,
                            )
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      </div>
                    </div>

                    {!optVersion && (
                      <div
                        id="Poppinsnormal"
                        style={{
                          width: "260px",
                          marginBottom: "10px",
                          position: "relative",
                          marginLeft: "0px",
                          height: "92px",
                          backgroundColor: "#F3F3F3",
                          borderRadius: "6px",
                          paddingTop: "-3px",
                          display: "flex",
                          paddingTop: "5px",
                          paddingLeft: "15px",
                        }}
                      >
                        <div>
                          <Tooltip title="">
                            <Badge
                              color="secondary"
                              badgeContent="!"
                              style={{ marginLeft: "5px" }}
                            />
                          </Tooltip>
                        </div>
                        <div
                          style={{
                            marginLeft: "20px",
                            fontFamily: "Helvetica",
                          }}
                        >
                          {i18n.t(
                            "El idioma del documento será español. Utilice el formato nuevo para que el idioma sea el mismo que el de la aplicación.",
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Box>
            )}
            <Grid item marginTop={"0rem"}>
              <Typography
                variant="body1"
                align="left"
                style={{
                  fontFamily: "Helvetica",
                  fontWeight: "300",
                  fontSize: "13px",
                  marginTop: "20px",
                  paddingRight: "10PX",
                  color: "#414141",
                  marginBottom: "20px",
                }}
              >
                {i18n.t(
                  "Podrás encontrar el archivo descargado en la carpeta Descargas en tu ordenador.",
                )}
              </Typography>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              marginTop={"1rem"}
              marginBottom={"3rem"}
            >
              {urlDownload == "pending" || exportSideBar.isLoading ? (
                <CircularProgress size={24} style={{ marginLeft: "1rem" }} />
              ) : (
                <button
                  className="AddProject"
                  style={{
                    marginTop: "-10px",
                    position: "inherit",
                    marginLeft: "30px",
                  }}
                  onClick={handleClick}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="BudgetDownload"
                  data-amplify-analytics-attrs={projectInfo}
                >
                  {i18n.t("Descargar")}
                </button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {openBlockModal && (
        <DownloadBlockModal
          open={openBlockModal}
          setOpen={setOpenBlockModal}
          name={name}
          tipo={tipo}
          typeBudget={typeBudget}
          muestraP={muestraP}
          muestraUser={muestraUser}
          muestrCompany={muestrCompany}
          muestraDescription={muestraDescription}
          soyFinal={soyFinal}
        />
      )}
      {openError && (
        <ModalCallError
          open={openError}
          setOpen={setOpenError}
          error={errorToSend}
          ubicacion={"modal Download Presupuesto"}
        />
      )}
      {openDownload && urlState != "" && (
        <ModalOpenDownload
          setOpenError={setOpenError}
          open={openDownload}
          setOpen={setOpenDownload}
          projectId={projectId}
          urlId={urlState}
        />
      )}
      {openModalWaitProcess &&
        !isNil(processState) &&
        processState.PK &&
        processState.SK && (
          <ModalWaitProcess
            PK={processState.PK}
            SK={processState.SK}
            open={openModalWaitProcess}
            setOpen={setOpenModalWaitProcess}
          />
        )}
    </>
  )
}
DownloadBudget.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  soyFinal: propTypes.bool,
  fromTab: propTypes.oneOf(Object.values(DownloadBudgetEnum)),
}
export default DownloadBudget
