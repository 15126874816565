import React, { useContext } from "react"
import propTypes from "prop-types"
import { useEffect, useState, useRef } from "react"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import { getLastRow } from "./estructureUtils"
import { getCurrency } from "utils/functions"
import ModalImportPartFromBc3Link from "pages/ModalPartidas/ModalImportPartFromBc3Link"
import Budget2023Context from "context/budget2023/Budget2023Context"

const Estructura = ({
  certificaciones,
  total,
  children,
  preferencias,
  desplazamiento,
  esUnico,
  soyFinal,
  totalFinal,
  alinearPrecio,
  ocultoFinal,
  capitulo,
  projectInfo,
  version,
  openModalCreatePartida,
  setOpenModalCreatePartida,
}) => {
  const [margen, setMargen] = useState("0px")
  const [openModalCreateFinal, setOpenModalCreateFinal] = useState(false)
  const { freeTrialExpired, openFreeTrialExpire, user } =
    useContext(AuthContext)
  const {
    toggleModalImportPartFromBc3Link,
    setChapterIdSelectedFromBc3Link,
    addCapituloV2,
  } = useContext(Budget2023Context)

  const handleCreatePart = () => {
    if (soyFinal) {
      setOpenModalCreateFinal(true)
    } else {
      setOpenModalCreatePartida(true)
      //createPartidaV2(user.sub, capitulo.capituloInfo.chapterId, capitulo.capituloInfo.projectId, 'last')
    }
  }
  const openModalImportFromBc3Link = () => {
    setChapterIdSelectedFromBc3Link(capitulo.capituloInfo.chapterId)
    toggleModalImportPartFromBc3Link(true)
  }

  const handleNewSubchapter = () => {
    addCapituloV2(
      {
        ...capitulo,
        clientPrice: 0,
        name: "",
        price: 0,
        tags: [],
        subcapitulos: [],
        parentId: capitulo.chapterId,
        partidas: capitulo.partidas,
        position: capitulo.subcapitulos.length,
        sended: "false",
        selfManagement: "false",
        root: "false",
      },
      capitulo.position,
    )
  }

  useEffect(() => {
    if (esUnico != undefined) {
      if (esUnico == true) {
        setMargen("20px")
      } else {
        setMargen("0px")
      }
    } else {
      setMargen("0px")
    }
  }, [])

  useEffect(() => {
    if (alineacion.current) {
      if (alinearPrecio == true) {
        alineacion.current.style.position = "relative"
        alineacion.current.style.marginTop = "0px"
        alineacion.current.style.right = "9%"
        alineacion.current.style.marginLeft = "133px"
      }
    }
  }, [alinearPrecio])

  const alineacion = useRef(null)
  const [colSpan, setColSpan] = useState(7)

  const countTrues = obj => {
    let count = 0
    Object.keys(obj).forEach(key => {
      count = obj[key] ? count + 1 : count
    })
    return count
  }

  useEffect(() => {
    //setColSpan(countTrues(preferencias) * 2)
  }, [preferencias])

  return (
    <div id="table-wrapper">
      <div id="table-scroll">
        <table
          className="tablaPartidas"
          style={{
            marginLeft: "0px",
            marginBottom: "8px",
            marginTop: capitulo.parentId == "root" ? "10px" : "0px",
            marginBottom: margen,
          }}
        >
          <tbody>
            <tr style={{ borderBottom: " solid #E9ECEF" }}>
              <th
                style={{
                  minWidth: "64px",
                  maxWidth: "96px",
                  paddingLeft: "15px",
                  borderLeft: "none",
                }}
              ></th>
              {preferencias.basededatos == true &&
              preferencias.clientPrice == true ? (
                <th
                  style={{
                    minWidth: "200px",
                    textAlign: "left",
                    paddingLeft: "57px",
                    borderLeft: "none",
                    width: "100%",
                  }}
                ></th>
              ) : preferencias.basededatos == false &&
                preferencias.clientPrice == false ? (
                <th
                  style={{
                    minWidth: certificaciones ? "200px" : "460px",
                    textAlign: "left",
                    paddingLeft: "57px",
                    borderLeft: "none",
                    width: "100%",
                  }}
                ></th>
              ) : (
                <th
                  style={{
                    minWidth: "300px",
                    textAlign: "left",
                    paddingLeft: "57px",
                    borderLeft: "none",
                    width: "100%",
                  }}
                ></th>
              )}
              {
                //UNIDAD Y LUEGO CANTIDAD
              }
              <th style={{ minWidth: "60px", maxWidth: "60px" }}></th>
              <th
                colSpan={colSpan == 0 && "2"}
                style={{ minWidth: "64px", maxWidth: "64px" }}
              ></th>
              {
                //MARGEN
              }
              {preferencias.basededatos == true ? (
                <th colSpan={"2"} style={{ minWidth: "86px" }}>
                  {i18n.t("")}
                </th>
              ) : null}
              {
                // Coste Objetivo
                preferencias.precioCoste && !certificaciones && (
                  <th
                    colSpan="2"
                    style={{
                      minWidth: "171px",
                      borderLeft: "1px solid #B7B7B7",
                    }}
                  >
                    {i18n.t("Coste Obj.")}
                  </th>
                )
              }
              {
                // Coste Real
                preferencias.costReal && !certificaciones && (
                  <th
                    colSpan={preferencias.basededatos == true ? 3 : 2}
                    style={{
                      minWidth: "171px",
                      borderLeft: "1px solid #B7B7B7",
                    }}
                  >
                    {i18n.t("Coste Real")}
                  </th>
                )
              }
              {
                // Precio Cliente
                (preferencias.clientPrice || certificaciones) && (
                  <th
                    colSpan="2"
                    style={{
                      minWidth: "128px",
                      borderLeft: "1px solid #B7B7B7",
                      borderRight: "1px solid #B7B7B7",
                    }}
                  >
                    {i18n.t("Precio Cliente")}
                  </th>
                )
              }
              {
                // Descuento
                preferencias.descuento && !certificaciones && (
                  <th
                    colSpan="2"
                    style={{
                      minWidth: "15px",
                      maxWidth: "250px",
                      background: "#F3D66338",
                      borderLeft: "1px solid #B7B7B7",
                    }}
                  >
                    {i18n.t("Precio Cliente con descuento")}
                  </th>
                )
              }
              {
                // Certificaciones
                certificaciones && (
                  <th
                    colSpan="7"
                    style={{
                      minWidth: "50px",
                      borderLeft: "1px solid #B7B7B7",
                      maxWidth: "86px",
                      color: "#414141",
                      background: "#F3D66338",
                    }}
                  >
                    {i18n.t("Certificaciones")}
                  </th>
                )
              }
              {
                // Opciones
                <th></th>
              }
            </tr>
          </tbody>

          <tbody>
            <tr id="subCategory">
              <td style={{ borderLeft: "none", width: "79px" }}>
                {i18n.t("Ref.")}
              </td>
              <td
                style={{
                  textAlign: "left",
                  borderLeft: "1px solid #E9ECEF",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "35px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      flex: "0 0 20%",
                      borderRight: "1px solid #E9ECEF",
                      paddingLeft: "10px",
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {i18n.t("Codigo")}
                  </div>
                  <div
                    style={{
                      flex: "0 0 80%",
                      paddingLeft: "10px",
                    }}
                  >
                    {i18n.t("Descripción")}
                  </div>
                </div>
              </td>
              <td
                style={{
                  minWidth: "60px",
                  maxWidth: "60px",
                  borderLeft: "1px solid #E9ECEF",
                }}
              >
                {i18n.t("Medición")}
              </td>
              <td
                colSpan={colSpan == 0 && "2"}
                style={{ borderLeft: "1px solid #E9ECEF" }}
              >
                {i18n.t("Ud.")}
              </td>
              {preferencias.basededatos == true ? (
                <>
                  <td
                    colSpan={"2"}
                    style={{
                      maxWidth: "86px",
                      borderLeft: "1px solid #E9ECEF",
                    }}
                  >
                    {i18n.t("Márgen")}
                  </td>
                </>
              ) : null}
              {
                // Coste Objetivo
                preferencias.precioCoste && !certificaciones && (
                  <>
                    <td style={{ minWidth: "86px", color: "#414141" }}>
                      {" "}
                      {getCurrency(user["custom:currency"])}/u
                    </td>
                    <td
                      style={{
                        minWidth: "126px",
                        color: "#414141",
                        borderLeft: "1px solid #F3F1F1",
                      }}
                    >
                      {i18n.t("Total")}
                    </td>
                  </>
                )
              }
              {
                // Coste Real
                preferencias.costReal && !certificaciones && (
                  <>
                    {preferencias.basededatos == true && (
                      <td style={{ minWidth: "86px", color: "#414141" }}>
                        %MR
                      </td>
                    )}
                    <td style={{ minWidth: "126px", color: "#414141" }}>
                      {getCurrency(user["custom:currency"])}/u
                    </td>
                    <td
                      style={{
                        minWidth: "126px",
                        color: "#414141",
                        borderLeft: "1px solid #F3F1F1",
                      }}
                    >
                      {i18n.t("Total")}
                    </td>
                  </>
                )
              }
              {
                // Precio Cliente
                preferencias.clientPrice && (
                  <>
                    <td style={{ minWidth: "72px" }}>
                      {getCurrency(user["custom:currency"])}/u
                    </td>
                    <td
                      style={{
                        minWidth: "96px",
                        borderLeft: "1px solid #F3F1F1",
                      }}
                    >
                      {i18n.t("Total")}
                    </td>
                  </>
                )
              }
              {
                // Descuento
                preferencias.descuento && !certificaciones && (
                  <>
                    <td style={{ minWidth: "126px" }}>
                      {getCurrency(user["custom:currency"])}/u
                    </td>
                    <td
                      style={{
                        minWidth: "126px",
                        borderLeft: "1px solid #F3F1F1",
                      }}
                    >
                      {i18n.t("Total")}
                    </td>
                  </>
                )
              }
              {
                // Certificaciones
                certificaciones && (
                  <>
                    <td
                      style={{
                        minWidth: "72px",
                        borderLeft: "1px solid #B7B7B7",
                        maxWidth: "86px",
                        color: "#414141",
                        background: "#F3D66338",
                      }}
                    >
                      {i18n.t("Total")}
                      <br />
                      {i18n.t("(Ud)")}
                    </td>
                    <td
                      style={{
                        minWidth: "72px",
                        borderLeft: "1px solid #B7B7B7",
                        maxWidth: "86px",
                        color: "#414141",
                        background: "#F3D66338",
                      }}
                    >
                      {i18n.t("Total")}
                      <br />
                      {i18n.t("(%)")}
                    </td>
                    <td
                      style={{
                        minWidth: "72px",
                        borderLeft: "1px solid #B7B7B7",
                        maxWidth: "86px",
                        color: "#414141",
                        background: "#F3D66338",
                      }}
                    >
                      {i18n.t("Previo")}
                      <br />
                      {i18n.t("(Ud)")}
                    </td>
                    <td
                      style={{
                        minWidth: "72px",
                        borderLeft: "1px solid #B7B7B7",
                        maxWidth: "86px",
                        color: "#414141",
                        background: "#F3D66338",
                      }}
                    >
                      {i18n.t("Previo")}
                      <br />
                      {i18n.t("%")}
                    </td>
                    <td
                      style={{
                        minWidth: "72px",
                        borderLeft: "1px solid #B7B7B7",
                        maxWidth: "86px",
                        color: "#414141",
                        background: "#F3D66338",
                      }}
                    >
                      {i18n.t("Actual")}
                      <br />
                      {i18n.t("(Ud)")}
                    </td>
                    <td
                      style={{
                        minWidth: "72px",
                        borderLeft: "1px solid #B7B7B7",
                        maxWidth: "86px",
                        color: "#414141",
                        background: "#F3D66338",
                      }}
                    >
                      {i18n.t("Actual")}
                      <br />
                      {i18n.t("(%)")}
                    </td>
                    <td
                      style={{
                        minWidth: "96px",
                        borderLeft: "1px solid #B7B7B7",
                        maxWidth: "128px",
                        color: "#414141",
                        background: "#F3D66338",
                      }}
                    >
                      {i18n.t("Actual")}
                      <br />
                      (€)
                    </td>
                  </>
                )
              }
              {
                // Opciones
                <td></td>
              }
            </tr>
          </tbody>

          {children}
          {projectInfo.status === "CLOSED" ||
          version ||
          certificaciones ? null : (
            <tbody>
              <tr id="lastPartida">
                <td
                  colSpan={colSpan == 0 ? "2" : "2"}
                  style={{ borderBottom: "none", textAlign: "left" }}
                >
                  <div
                    className="NombreAlign"
                    style={{ left: "5px", justifyContent: "left" }}
                    onClick={() => {
                      freeTrialExpired
                        ? openFreeTrialExpire()
                        : handleCreatePart()
                    }}
                  >
                    <button
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="AddNewPartPartTable"
                      data-amplify-analytics-attrs={capitulo}
                      className="nuevoBotonCrear4"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      + {i18n.t("Buscar o crear nueva partida")}
                    </button>
                  </div>
                  <div
                    className="NombreAlign"
                    style={{ left: "5px", justifyContent: "left" }}
                    onClick={() => {
                      freeTrialExpired
                        ? openFreeTrialExpire()
                        : openModalImportFromBc3Link()
                    }}
                  >
                    <button
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="AddNewPartPartTable"
                      data-amplify-analytics-attrs={capitulo}
                      className="nuevoBotonCrear4"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      {i18n.t("importBC3Link.link")}
                    </button>
                  </div>
                </td>
                {getLastRow(preferencias, capitulo)}
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  )
}

Estructura.propTypes = {
  total: propTypes.any.isRequired,
  children: propTypes.node.isRequired,
  preferencias: propTypes.object.isRequired,
  setPreferencias: propTypes.func.isRequired,
  desplazamiento: propTypes.number.isRequired,
  esUnico: propTypes.bool,
  soyFinal: propTypes.bool,
  totalFinal: propTypes.any,
  alinearPrecio: propTypes.bool,
  ocultoFinal: propTypes.bool,
  capitulo: propTypes.object.isRequired,
  projectInfo: propTypes.object,
  version: propTypes.object,
  openModalCreatePartida: propTypes.bool,
  setOpenModalCreatePartida: propTypes.func,
  certificaciones: propTypes.bool,
}

export default Estructura
