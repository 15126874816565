import { a, API, Auth } from "aws-amplify"
import Budget2023Reducer from "./Budget2023Reducer"
import Budget2023Context from "./Budget2023Context"
import React, { useReducer } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import {
  getChapterParentId,
  getChapterParentId2,
  changesPartidasById,
  getChapterById,
  getPartIndex,
  getChapterIndex,
  getChapterByParentAndPosition,
  getPartByParentAndPosition,
  swapPartidasPosition,
  getNextPartByParentAndPosition,
  getPreviousPartByParentAndPosition,
} from "utils/budgetUtils"
import Chapter from "./Chapter"
import Part from "./Part"
import * as amplitude from "@amplitude/analytics-browser"

import {
  SENDING_MESSAGE,
  SEND_COMPLETE,
  RESET_SEND,
  SET_FOOTER_VALUES,
  DEL_CAPITULO_SET_POSITION,
  SET_BC3_LINK_DATA,
  TOGGLE_MODAL_IMPORT_BC3_LINK,
  IS_IMPORTING_PART_FROM_EDIT_MODAL,
  SET_MODAL_BUDGETING_ERROR,
  REMOVE_ALL_PARTS_FROM_CHAPTER,
  SET_CHAPTER_ID_SELECTED_FROM_BC3LINK,
  IS_CONTRADICTING_PART,
  SET_EXTRA_AND_CONTRADITIONS,
  LOADING_EXTRA_AND_CONTRADITIONS,
  SET_FOOTER_VALUES_FOR_EXTRA_AND_CONTRADITIONS,
  SET_LOADING_ICON,
  IS_BUDGET_CLOSED,
  MOVE_PARTS_BY_CHAPTERS_ID,
  SET_DYNAMIC_ELEMENT_MODAL_IS_OPEN,
  SET_DYNAMIC_ELEMENT_SELECTED,
  SET_DYNAMIC_ELEMENT_PROJECT_ID,
  SET_GENERAL_UPDATE,
  CERTIFICATION_LOADING,
} from "../types"
import { CORE_URL } from "utils/functions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { isNil } from "lodash"

const Budget2023State = props => {
  const initialState = {
    budgetTree: new Chapter({
      capituloInfo: {
        chapterId: "root",
      },
      partidas: null,
      subcapitulos: null,
    }),
    extraAndContradictions: new Chapter({
      capituloInfo: {
        chapterId: "root",
      },
      partidas: null,
      subcapitulos: null,
    }),
    dynamicElement: {
      modalIsOpen: false,
      setModalIsOpen: () => {},
      selected: null,
      setSelected: () => {},
      selected: null,
      setSelected: () => {},
      projectId: null,
      setProjectId: () => {},
    },
    loading: true,
    loadingRecalc: false,
    updating: false,
    readyToClose: false,
    urlDownload: "",
    selectedBudget2: [],
    selectedPartGroup: [],
    errorAgrupar: false,
    changeSidebar: 0,
    selectedChaptersGroup: [],
    partsChecked: [],
    chaptersChecked: [],
    imChecked: { isChecked: false, partida: null },
    imChecked2: { isChecked: false, capitulo: null },
    tags: [],
    tagsFiltered: [],
    budgetPdf: null,
    loadingBudgetPdf: false,
    versionToDisplay: {},
    loadingAgruparChapters: false,
    loadingAgruparParts: false,
    sendChapters: [],
    loadingSend: false,
    sendOk: false,
    preferencias: {
      basededatos: false, //si hay margen global a true
      clientPrice: true,
      descuento: false, //si el discount es >0 true
      objetivo: true,
      procedencia: true,
      precioCoste: true,
    },
    copiedData: null,
    lastActions: [],
    bc3LinkData: null,
    openModalImportPartFromBc3Link: false,
    isImportingPartFromEditModal: false,
    isContradictingPart: false,
    chapterIdSelectedFromBc3Link: null,
    modalBudgetingError: {
      isOpen: false,
      error: null,
      location: "",
    },
    loadingIcon: {
      isLoading: false,
      index: null,
    },
    isBudgetClosed: false,
    certification: {
      generalUpdate: false,
      setGeneralUpdate: () => {},
      loading: false,
    },
  }
  const location = useLocation()
  const isExtrasPath = location.pathname.includes("/extras")

  const APINAME = "dev-PLANHOPPER-API"
  const SERVICE_BUDGET = "service-budget"
  const SERVICE_BUDGET_TEMP = "service-budgeting-temp"
  const SERVICE_PART_CORE = "service-part-core"
  const SERVICE_CORE_BASE = "service-core-base"

  const [state, dispatch] = useReducer(Budget2023Reducer, initialState)

  const setPreferencias = preferencias => {
    dispatch({
      type: "SET_PREFERENCIAS",
      payload: {
        preferencias: preferencias,
      },
    })
  }

  const getCommonHeaders = async () => {
    const commonHeaders = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    return commonHeaders
  }

  async function deleteChapterRecursive(listaCapitulos, capitulo) {
    for (let i = 0; i < listaCapitulos.length; i++) {
      if (listaCapitulos[i].chapterId === capitulo.chapterId) {
        listaCapitulos.splice(i, 1)
        break
      } else {
        deleteChapterRecursive(listaCapitulos[i].subcapitulos, capitulo)
      }
    }
  }

  function getSelectedBudget(selectedBudget, capitulos) {
    for (let i = 0; i < capitulos.length; i++) {
      if (capitulos[i].checked) {
        selectedBudget.push({ ...capitulos[i] })

        if (capitulos[i].partidas.length > 0) {
          let copia = []
          for (let j = 0; j < capitulos[i].partidas.length; j++) {
            if (capitulos[i].partidas[j].checked == true) {
              copia.push(capitulos[i].partidas[j])
            }
          }
          selectedBudget[selectedBudget.length - 1].partidas = copia
        }
      }
      if (capitulos[i].subcapitulos.length > 0) {
        getSelectedBudget(selectedBudget, capitulos[i].subcapitulos)
      }
    }
    if (selectedBudget.length == 0) {
      selectedBudget = state.budgetTree.subcapitulos
    }
    dispatch({
      type: "SET_SELECTED",
      payload: {
        selectedBudget: selectedBudget,
      },
    })
  }

  function getSelectedChapters(selectedBudget, auxList, totalCaps, start) {
    for (let i = 0; i < totalCaps.length; i++) {
      if (totalCaps[i].checked) {
        if (totalCaps[i].subcapitulos.length > 0) {
          let auxChapter = { ...totalCaps[i] }
          auxChapter.subcapitulos = []
          auxList.push(auxChapter)
          getSelectedChapters(
            selectedBudget,
            auxList[auxList.length - 1].subcapitulos,
            totalCaps[i].subcapitulos,
            start,
          )
        } else if (totalCaps[i].partidas.length > 0) {
          let auxChapter = { ...totalCaps[i] }
          auxChapter.partidas = []
          let copia = []
          for (let j = 0; j < totalCaps[i].partidas.length; j++) {
            if (totalCaps[i].partidas[j].checked == true) {
              copia.push(totalCaps[i].partidas[j])
            }
          }
          auxChapter.partidas = copia
          auxList.push(auxChapter)
        }
      } else {
        if (totalCaps[i].subcapitulos.length > 0) {
          getSelectedChapters(
            selectedBudget,
            auxList,
            totalCaps[i].subcapitulos,
            start,
          )
        }
      }
    }
    if (auxList.length == 0) {
      auxList = state.budgetTree.subcapitulos
    }
    dispatch({
      type: "SET_SELECTED",
      payload: {
        selectedBudget: auxList,
      },
    })
  }

  function getSelectedBudget2(selectedBudget, capitulos) {
    for (let i = 0; i < capitulos.length; i++) {
      if (capitulos[i].checked) {
        selectedBudget.push({ ...capitulos[i].capituloInfo })
      }
      if (capitulos[i].subcapitulos.length > 0) {
        getSelectedBudget2(selectedBudget, capitulos[i].subcapitulos)
      }
    }
    dispatch({
      type: "SELECCIONAR_AGRUPAR",
      payload: {
        selectedBudget: selectedBudget,
      },
    })
  }

  function selectPartsGroup(selectedParts, capitulos) {
    for (let i = 0; i < capitulos.length; i++) {
      if (capitulos[i].checked) {
        dispatch({
          type: "ERROR_CAPITULO",
          payload: {
            error: true,
          },
        })
        return false
      } else {
        if (capitulos[i].partidas.length > 0) {
          let copia = []
          for (let j = 0; j < capitulos[i].partidas.length; j++) {
            if (capitulos[i].partidas[j].checked == true) {
              copia.push(capitulos[i].partidas[j])
            }
          }
          if (copia.length > 0) {
            if (!copia.isEmpty) {
              selectedParts.push(copia)
            }
          }
        }
        if (capitulos[i].subcapitulos.length > 0) {
          selectPartsGroup(selectedParts, capitulos[i].subcapitulos)
        }
      }
    }
    dispatch({
      type: "SET_GROUP",
      payload: {
        selectedParts: selectedParts,
        error: false,
      },
    })
  }

  const getBudgetTree = async (projectId, soyFinal) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const path = "/project/full/" + projectId
      const respFullProject = await API.get(SERVICE_BUDGET, path, payload)

      const { price, finalPrice, finalPriceWithoutDiscount, is_closed } =
        respFullProject

      const chapterRoot = new Chapter({
        capituloInfo: {
          chapterId: "root",
        },
        partidas: null,
        subcapitulos: null,
        price: price,
        clientPrice: finalPrice,
        clientPriceWithoutDiscount: finalPriceWithoutDiscount,
        totalDiscount: 0,
      })
      setIsBudgetClosed(is_closed)
      dispatch({
        type: "LOADING_BUDGET",
        payload: {
          loading: true,
          budgetTree: chapterRoot,
        },
      })

      let subcapitulosAux = respFullProject.chapters.map(chapter => {
        const { chapters, partidas, ...rest } = chapter
        const chapterFormatted = {
          ...rest,
          partidas: partidas.map(p => ({ partidaId: p })),
        }
        const oChapter = new Chapter({
          ...chapterFormatted,
          capituloInfo: chapterFormatted,
        })
        oChapter.fillAllFromRoot(chapter.chapterId, chapter.chapters)
        oChapter.updateCostReal()
        if (oChapter.partidas && oChapter.partidas.length === 0) {
          oChapter.totalCertPreviousChapter = oChapter.subcapitulos.reduce(
            (acc, cap) => acc + cap.totalCertPreviousChapter,
            0,
          )
          oChapter.totalCertCurrentChapter = oChapter.subcapitulos.reduce(
            (acc, cap) => acc + cap.totalCertCurrentChapter,
            0,
          )
        } else {
          oChapter.totalCertPreviousChapter =
            oChapter.calculatePreviousTotalChapter()
          oChapter.totalCertCurrentChapter =
            oChapter.calculateCurrentTotalChapter()
        }
        return oChapter
      })

      if (subcapitulosAux.length === 0) {
        const response = await API.get(
          SERVICE_BUDGET,
          `/project/${projectId}?parentId=root`,
          payload,
        )

        subcapitulosAux = response.chapters.map(
          capitulo => new Chapter({ ...capitulo, capituloInfo: capitulo }),
        )
      }

      dispatch({
        type: "SET_BUDGET_TREE",
        payload: subcapitulosAux,
      })

      dispatch({
        type: SET_FOOTER_VALUES,
      })
    } catch (e) {
      dispatch({
        type: "ERR_BUDGET_TREE",
        payload: { loading: false },
      })
    }
  }

  const setExtraAndContraditions = async projectId => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }

      const respExtraContraditions = await API.get(
        SERVICE_CORE_BASE,
        `/extras/${projectId}`,
        payload,
      )

      const { price, finalPrice, finalPriceWithoutDiscount } =
        respExtraContraditions

      const chapterRoot = new Chapter({
        capituloInfo: {
          chapterId: "root",
        },
        partidas: null,
        subcapitulos: null,
        price: price,
        clientPrice: finalPrice,
        clientPriceWithoutDiscount: finalPriceWithoutDiscount,
        totalDiscount: 0,
      })

      setIsBudgetClosed(is_closed)

      dispatch({
        type: LOADING_EXTRA_AND_CONTRADITIONS,
        payload: {
          loading: true,
          extraAndContradictions: chapterRoot,
        },
      })

      let subcapitulosAux = respExtraContraditions.chapters.map(chapter => {
        const { chapters, partidas, ...rest } = chapter
        const chapterFormatted = {
          ...rest,
          partidas: partidas.map(p => ({ partidaId: p })),
        }
        const oChapter = new Chapter({
          ...chapterFormatted,
          capituloInfo: chapterFormatted,
        })
        oChapter.fillAllFromRoot(chapter.chapterId, chapter.chapters)
        if (oChapter.partidas && oChapter.partidas.length === 0) {
          oChapter.totalCertPreviousChapter = oChapter.subcapitulos.reduce(
            (acc, cap) => acc + cap.totalCertPreviousChapter,
            0,
          )
          oChapter.totalCertCurrentChapter = oChapter.subcapitulos.reduce(
            (acc, cap) => acc + cap.totalCertCurrentChapter,
            0,
          )
        } else {
          oChapter.totalCertPreviousChapter =
            oChapter.calculatePreviousTotalChapter()
          oChapter.totalCertCurrentChapter =
            oChapter.calculateCurrentTotalChapter()
        }
        return oChapter
      })

      if (subcapitulosAux.length === 0) {
        const response = await API.get(
          SERVICE_BUDGET,
          `/project/${projectId}?parentId=root`,
          payload,
        )

        subcapitulosAux = response.chapters.map(
          capitulo => new Chapter({ ...capitulo, capituloInfo: capitulo }),
        )
      }

      dispatch({
        type: SET_EXTRA_AND_CONTRADITIONS,
        payload: subcapitulosAux,
      })

      dispatch({
        type: SET_FOOTER_VALUES_FOR_EXTRA_AND_CONTRADITIONS,
      })
    } catch (e) {
      dispatch({
        type: "ERR_BUDGET_TREE",
        payload: { loading: false },
      })
    }
  }

  const setExtraAndContraditionsCouldDelete = async projectId => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }

      const respExtraContraditions = await API.get(
        SERVICE_CORE_BASE,
        `/extras/${projectId}`,
        payload,
      )

      const { price, finalPrice, finalPriceWithoutDiscount } =
        respExtraContraditions

      const chapterRoot = new Chapter({
        capituloInfo: {
          chapterId: "root",
        },
        partidas: null,
        subcapitulos: null,
        price: price,
        clientPrice: finalPrice,
        clientPriceWithoutDiscount: finalPriceWithoutDiscount,
        totalDiscount: 0,
      })

      dispatch({
        type: LOADING_EXTRA_AND_CONTRADITIONS,
        payload: {
          loading: true,
          extraAndContradictions: chapterRoot,
        },
      })

      let subcapitulosAux = respExtraContraditions.chapters.map(chapter => {
        const { chapters, partidas, ...rest } = chapter
        const chapterFormatted = {
          ...rest,
          partidas: partidas.map(p => ({ partidaId: p })),
        }
        const oChapter = new Chapter({
          ...chapterFormatted,
          capituloInfo: chapterFormatted,
        })
        oChapter.fillAllFromRoot(chapter.chapterId, chapter.chapters)
        if (oChapter.partidas && oChapter.partidas.length === 0) {
          oChapter.totalCertPreviousChapter = oChapter.subcapitulos.reduce(
            (acc, cap) => acc + cap.totalCertPreviousChapter,
            0,
          )
          oChapter.totalCertCurrentChapter = oChapter.subcapitulos.reduce(
            (acc, cap) => acc + cap.totalCertCurrentChapter,
            0,
          )
        } else {
          oChapter.totalCertPreviousChapter =
            oChapter.calculatePreviousTotalChapter()
          oChapter.totalCertCurrentChapter =
            oChapter.calculateCurrentTotalChapter()
        }
        return oChapter
      })

      if (subcapitulosAux.length === 0) {
        const response = await API.get(
          SERVICE_BUDGET,
          `/project/${projectId}?parentId=root`,
          payload,
        )

        subcapitulosAux = response.chapters.map(
          capitulo => new Chapter({ ...capitulo, capituloInfo: capitulo }),
        )
      }

      dispatch({
        type: SET_EXTRA_AND_CONTRADITIONS,
        payload: subcapitulosAux,
      })

      dispatch({
        type: SET_FOOTER_VALUES_FOR_EXTRA_AND_CONTRADITIONS,
      })
    } catch (e) {
      dispatch({
        type: "ERR_BUDGET_TREE",
        payload: { loading: false },
      })
    }
  }

  const getSubCapitulosContent = async (projectId, chapterId) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const response = await API.get(
        SERVICE_BUDGET,
        `/project/${projectId}?parentId=` + chapterId,
        payload,
      )
      dispatch({
        type: "FILL_CHAPTER_CONTENT",
        payload: {
          chapterId: chapterId,
          content: response,
        },
      })
    } catch (e) {
      dispatch({
        type: "ERR_BUDGET_TREE",
        payload: { loading: false },
      })
    }
  }

  const getSubCapitulosContent2 = async (projectId, chapterId) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const response = await API.get(
        SERVICE_BUDGET,
        `/project/${projectId}?parentId=` + chapterId,
        payload,
      )
      dispatch({
        type: "FILL_CHAPTER_CONTENT_2",
        payload: {
          chapterId: chapterId,
          content: response,
        },
      })
    } catch (e) {
      dispatch({
        type: "ERR_BUDGET_TREE",
        payload: { loading: false },
      })
    }
  }

  const updateCapitulov2 = async data => {
    try {
      dispatch({
        type: "ON_UPDATE",
      })
      let headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      let body = { ...data, organizationId: data.capituloInfo.organizationId }
      const res = await API.put(
        SERVICE_BUDGET_TEMP,
        "/chapter/" + body.chapterId,
        { headers: headers, body: body },
      )
      dispatch({
        type: "UPDATE_CAPITULO",
        payload: {
          newData: res.chapter,
          capituloId: res.chapter.chapterId,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }
  const updatePartidav2 = async (data, capituloId, accountId, soyManual) => {
    try {
      dispatch({
        type: "ON_UPDATE",
      })

      dispatch({
        type: CERTIFICATION_LOADING,
        payload: true,
      })

      let headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      let auxDecomposition = data.decomposition
      if (data.decomposition.length > 0) {
        for (let i = 0; i < data.decomposition.length; i++) {
          auxDecomposition[i].price = parseFloat(
            auxDecomposition[i].price.toString().replace(",", "."),
          )
          auxDecomposition[i].quantity = parseFloat(
            auxDecomposition[i].quantity.toString().replace(",", "."),
          )
          auxDecomposition[i].type =
            auxDecomposition[i].type == "coste"
              ? 0
              : auxDecomposition[i].type == "manodeobra"
                ? 1
                : auxDecomposition[i].type == "maquinaria"
                  ? 2
                  : auxDecomposition[i].type == "residuoAdicional"
                    ? 4
                    : auxDecomposition[i].type == "residuoClasificacion"
                      ? 5
                      : auxDecomposition[i].type == "material"
                        ? 3
                        : 1
        }
      }
      let deleteMeasures = false
      if (data?.measures.length == 1) {
        if (
          data.measures[0].description == "" &&
          data.measures[0].quantity == 0 &&
          data.measures[0].total == 0 &&
          data.measures[0].height == 0 &&
          data.measures[0].depth == 0 &&
          data.measures[0].width == 0
        ) {
          deleteMeasures = true
        }
      }
      let body = {
        ...data,
        name: data.name,
        accountId: accountId,
        projectId: data.projectId,
        partidaId: data.partidaId,
        decomposition: auxDecomposition ? auxDecomposition : [],
        description: data.description ? data.description : data.resumen,
        quantity: data.quantity ? parseFloat(data.quantity) : 0,
        code: data.code,
        unity: data.unity ? data.unity : null,
        status: "OPEN",
        // data.margin could be 0, so we need to check if it is defined
        costReal: data.costReal ? parseFloat(data.costReal) : null,
        costeGeneral: data.hasOwnProperty("margin")
          ? parseFloat(data.margin)
          : data.costeGeneral
            ? parseFloat(data.costeGeneral)
            : 0,
        costeIndirecto: data.costeIndirecto
          ? parseFloat(data.costeIndirecto)
          : 0,
        industrialProfit: data.industrialProfit
          ? parseFloat(data.industrialProfit)
          : 0,
        measures: data.measures ? (deleteMeasures ? [] : data.measures) : [],
        soyManual: soyManual ? soyManual : 0,
        discount: data.discount ? data.discount : 0,
        comeFrom: data.comeFrom ? data.comeFrom : null,
        price: data.price ? parseFloat(data.price) : 0,
        refPrice: data.refPrice ? parseFloat(data.refPrice) : 0,
        clientPrice: data.clientPrice ? parseFloat(data.clientPrice) : 0,
      }

      const res = await API.put(
        SERVICE_BUDGET_TEMP,
        "/part/" + data.partidaId,
        { headers: headers, body: body },
      )
      const partidaUpdated2 = {
        partidaId: res,
        elementInfo: null,
      }
      dispatch({
        type: "UPDATE_PARTIDA",
        payload: {
          newData: partidaUpdated2,
          capituloId: capituloId,
          final: false,
        },
      })

      dispatch({
        type: CERTIFICATION_LOADING,
        payload: false,
      })
    } catch (e) {
      console.log(e)
      dispatch({
        type: CERTIFICATION_LOADING,
        payload: false,
      })
    }
  }

  const updatePreciosFinalesv2 = async (data, capituloId, estoyEnFinal) => {
    try {
      dispatch({
        type: "ON_UPDATE",
      })
      let headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      let body = {}
      if (data.industrialProfit) {
        body = {
          name: data.name,
          projectId: data.projectId,
          partidaId: data.partidaId,
          description: data.description ? data.description : data.resumen,
          quantity: data.quantity
            ? Number(data.quantity)
            : Number(data.unidades),
          code: data.code,
          userPrice: data.userPrice ? data.userPrice : 0,
          unity: data.unity ? data.unity : null,
          costeIndirecto: data.costeIndirecto,
          industrialProfit: data.industrialProfit,
          costeGeneral: data.hasOwnProperty("margin")
            ? parseFloat(data.margin)
            : data.costeGeneral
              ? parseFloat(data.costeGeneral)
              : 0,
          costeFinal: parseFloat(data.costeFinal),
          status: "OPEN",
        }
      } else {
        body = {
          name: "",
          projectId: data.projectId,
          partidaId: data.partidaId,
          description: data.resumen,
          quantity: Number(data.unidades),
          code: data.code,
          userPrice: data.userPrice ? data.userPrice : 0,
          costeFinal: data.costeFinal,
          costeGeneral: data.hasOwnProperty("margin")
            ? parseFloat(data.margin)
            : data.costeGeneral
              ? parseFloat(data.costeGeneral)
              : 0,
          unity: data.unity ? data.unity : null,
          status: "OPEN",
        }
      }
      const res = await API.put(APINAME, "/api/budget/update/partida", {
        headers: headers,
        body: body,
      })
      const partidaUpdated = {
        partidaId: res.partida,
        elementInfo: res.elementInfo,
      }
      dispatch({
        type: "UPDATE_PARTIDA",
        payload: {
          newData: partidaUpdated,
          capituloId: capituloId,
          final: estoyEnFinal,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const deleteCapitulo = async (capituloId, projectId) => {
    try {
      dispatch({
        type: "ON_UPDATE",
      })
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.del(
        SERVICE_BUDGET_TEMP,
        "/chapter/" + capituloId + "?projectId=" + projectId,
        payload,
      )

      dispatch({
        type: "DEL_CAPITULO",
        payload: { capituloId: capituloId, totalDiscount: res.totalDiscount },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteCapituloAndSetPosition = async (capituloId, projectId) => {
    try {
      dispatch({
        type: "ON_UPDATE",
      })
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.del(
        SERVICE_BUDGET_TEMP,
        "/chapter/" + capituloId + "?projectId=" + projectId,
        payload,
      )

      dispatch({
        type: DEL_CAPITULO_SET_POSITION,
        payload: { capituloId: capituloId, totalDiscount: res.totalDiscount },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateCurrentPercentageChapterCertification = async data => {
    try {
      dispatch({
        type: CERTIFICATION_LOADING,
        payload: true,
      })
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      let resp = await axios.post(
        CORE_URL + "/cert/chapter/update_current",
        data,
        { headers: payload.headers },
      )
      dispatch({
        type: CERTIFICATION_LOADING,
        payload: false,
      })
      return resp
    } catch (error) {
      console.log(error)
      dispatch({
        type: CERTIFICATION_LOADING,
        payload: false,
      })
      return error
    }
  }

  const closeCertification = async projectId => {
    try {
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }

      axios.post(CORE_URL + "/cert/create/" + projectId, null, {
        headers: payload.headers,
      })

      return true
    } catch (error) {
      return false
      console.log(error)
    }
  }

  const addCapituloV2 = async (data, indexCapitulo) => {
    try {
      dispatch({
        type: "ON_UPDATE",
      })

      let commonHeaders = getCommonHeaders()
      const payload2 = {
        ...commonHeaders,
        body: null,
      }
      const res = await API.post(
        SERVICE_CORE_BASE,
        data.chapterId
          ? `/chapters/create?projectId=${data.projectId}&parentId=${data.parentId}`
          : `/chapters/create?projectId=${data.projectId}`,
        payload2,
      )

      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: res.chapterId ? res.chapterId : "root",
          res: { ...res.chapters[0], capituloInfo: res.chapters[0] },
          indexCapitulo: res.chapters[0].position,
        },
      })

      if (data.partidas.length > 0) {
        dispatch({
          type: MOVE_PARTS_BY_CHAPTERS_ID,
          payload: {
            parentId: res.chapterId,
            chapterId: res.chapters[0],
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const deleteMultipleParts = async user => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      while (state.partsChecked.length > 0) {
        const res = await API.del(
          SERVICE_CORE_BASE,
          `/partida/?partidaId=${state.partsChecked[0].partidaId}`,
          payload,
        )
        dispatch({
          type: "DEL_PARTIDA",
          payload: {
            capituloId: state.partsChecked[0].chapterId,
            partidaId: state.partsChecked[0].partidaId,
            totalDiscount: res.totalDiscount,
          },
        })
        state.partsChecked.shift()
      }
      cleanParts()
    } catch (error) {
      console.log(error)
    }
  }
  const deletePartidav2 = async (capituloId, partidaId, accountId) => {
    dispatch({
      type: "ON_UPDATE",
    })
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }

      const res = await API.del(
        SERVICE_CORE_BASE,
        `/partida/?partidaId=${partidaId}`,
        payload,
      )
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId,
          partidaId,
          totalDiscount: res.totalDiscount,
        },
      })
      cleanParts()
      dispatch({
        type: "CLEAN_PARTS",
      })
      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
    }
  }
  const createPartidaV2 = async (
    accountId,
    chapterId,
    projectId,
    index,
    part,
    data,
    extraChapter = null,
  ) => {
    let chapter = !isNil(extraChapter)
      ? extraChapter
      : await getChapterById(chapterId, state.budgetTree.subcapitulos)
    if (extraChapter) {
      chapter.partidas = chapter.parts
    }
    let indicePartida = 0
    if (chapter.capituloInfo) {
      if (chapter.partidas) {
        if (part) {
          indicePartida = await getPartIndex(chapter, part.partidaId)
          indicePartida = indicePartida + 1
        } else {
          indicePartida = 0
        }
      }
    }
    dispatch({
      type: "ON_UPDATE",
    })
    try {
      let auxRefPrice = 0
      if (data.refPrice) {
        auxRefPrice = parseFloat(data.refPrice)
      } else if (data.refPrice == 0) {
        auxRefPrice = 0
      } else if (data.price) {
        auxRefPrice = parseFloat(data.price)
      } else {
        auxRefPrice = 0
      }
      let auxDecomposition = data.decomposition

      if (data.decomposition.length > 0) {
        for (let i = 0; i < data.decomposition.length; i++) {
          auxDecomposition[i].price = parseFloat(
            auxDecomposition[i].price.replace(",", "."),
          )
          auxDecomposition[i].quantity = parseFloat(
            auxDecomposition[i].quantity.replace(",", "."),
          )
          auxDecomposition[i].type =
            auxDecomposition[i].type == "coste"
              ? 0
              : auxDecomposition[i].type == "manodeobra"
                ? 1
                : auxDecomposition[i].type == "maquinaria"
                  ? 2
                  : auxDecomposition[i].type == "residuoAdicional"
                    ? 4
                    : auxDecomposition[i].type == "residuoClasificacion"
                      ? 5
                      : auxDecomposition[i].type == "material"
                        ? 3
                        : 1
        }
      }
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          ...data,
          accountId: accountId,
          chapterId: chapterId,
          code: data ? (data.code ? data.code : null) : null,
          name: data ? data.name : "",
          description: data.resumen
            ? data.resumen
            : data.description
              ? data.description
              : "",
          quantity: data.quantity ? parseFloat(data.quantity) : 0,
          unity: data ? (data.unity ? data.unity : null) : null,
          price: data ? (data.price ? parseFloat(data.price) : 0) : 0,
          projectId: !isNil(extraChapter) ? chapter.projectId : projectId,
          status: "OPEN",
          position:
            index === "last" ? chapter?.partidas?.length : indicePartida,
          comeFrom: data ? data.comeFrom : null,
          refPrice: auxRefPrice,
          clientPrice: 0,
          costeGeneral: data.hasOwnProperty("margin")
            ? parseFloat(data.margin)
            : data.costeGeneral
              ? parseFloat(data.costeGeneral)
              : 0,
          measures: data.measures,
          costeIndirecto: 0,
          industrialProfit: 0,
          decomposition: auxDecomposition ? auxDecomposition : [],
        },
      }
      const partida = await API.post(SERVICE_BUDGET_TEMP, "/part", payload)
      let aux = {
        partidaId: partida,
        elementInfo: null,
        chapterId: chapterId,
        creadaEnFinal: false,
      }
      let tmpIndex = chapter.partidas.length
      let partidaNueva = new Part(aux)
      dispatch({
        type: "ADD_PARTIDA",
        payload: {
          chapterId,
          partidaNueva,
          tmpIndex: tmpIndex + 1,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  const addImageToPart = async (
    accountId,
    partidaId,
    chapterId,
    projectId,
    file,
    extension,
    elementInfo,
  ) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          chapterId: chapterId,
          partId: partidaId,
          accountId: accountId,
          projectId: projectId,
          filename: file.path,
          size: file.size,
          tags: ["foto"],
          extension: extension,
          contentType: file.type,
        },
      }
      const res = await API.post(APINAME, "/api/part/addImageToPart", payload)
      const partidaUpdated = {
        partidaId: res.partida,
        elementInfo: elementInfo,
      }
      const formData = new FormData()
      Object.keys(res.uploadInfo.fields).forEach(key => {
        formData.append(key, res.uploadInfo.fields[key])
      })
      formData.append("file", file)
      const res2 = await axios.post(res.uploadInfo.url, formData)
      dispatch({
        type: "ADD_IMAGE",
        payload: {
          newData: partidaUpdated,
          capituloId: chapterId,
          final: false,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteImageFromPart = async (
    accountId,
    partidaId,
    chapterId,
    fileId,
    elementInfo,
  ) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          partId: partidaId,
          accountId: accountId,
          fileId: fileId,
        },
      }
      const res = await API.del(
        APINAME,
        "/api/part/removeImageFromPart",
        payload,
      )
      const partidaUpdated = {
        partidaId: res.partida,
        elementInfo: elementInfo,
      }
      dispatch({
        type: "ADD_IMAGE",
        payload: {
          newData: partidaUpdated,
          capituloId: chapterId,
          final: false,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateIsExpanded = async capituloId => {
    dispatch({
      type: "UPDATE_IS_EXPANDED",
      payload: capituloId,
    })
  }

  const checkCapitulos = (capituloId, value) => {
    dispatch({
      type: "CHECK_CAPITULOS",
      payload: {
        capituloId,
        value,
      },
    })
  }

  const checkPartida = (capituloId, partidaId) => {
    dispatch({
      type: "CHECK_PARTIDA",
      payload: {
        capituloId,
        partidaId,
      },
    })
  }
  const uncheckAll = () => {
    dispatch({
      type: "UNCHECK_ALL",
    })
  }
  const closeBudget = () => {
    dispatch({
      type: "CLOSE_BUDGET",
    })
  }
  const isUpdates = () => {
    dispatch({
      type: "CHECK_UPDATES",
    })
  }
  const downloadBudget = async (
    projectId,
    name,
    typeDocument,
    typeBudget,
    options,
  ) => {
    try {
      let tipo = ""
      if (typeBudget == "PC") {
        tipo = "normal"
      } else {
        tipo = "final"
      }

      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      dispatch({
        type: "DOWNLOADING_BUDGET",
      })
      let route = ""
      let extension = ""
      let auxCaps = []
      for (let i = 0; i < state.chaptersChecked.length; i++) {
        auxCaps.push(state.chaptersChecked[i].chapterId)
      }
      if (typeDocument == "pdf") {
        route = "/project/download/" + projectId
        extension = ".pdf"
        let body = {
          chapters: auxCaps,
          typeDocument: "pdf",
          preview: false,
          briefing: options.briefing,
          options: { ...options, prices: options.price },
          objectivePrice: options.price,
          typeBudget: tipo == "normal" ? "cost" : "client",
        }
        const res = await API.post("service-budget", route, {
          headers: payload.headers,
          body: body,
        })

        axios({
          url: res.url,
          method: "GET",
          responseType: "blob",
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", name + extension) //or any other extension
          document.body.appendChild(link)
          link.click()
        })

        amplitude.track({
          event_type: "Budget PDF Downloaded",
          event_properties: { projectId: projectId, projectName: name },
        })
        dispatch({
          type: "DOWNLOAD_BUDGET",
        })
      } else if (typeDocument == "excel") {
        route = `/excel/s3/${projectId}`
        extension = ".xlsx"

        const params = {
          language: "es", // Intl, debería tomarlos del account
          currency: "EUR", //       para más adelante
          cost_price: options.cost, // Precio Coste Objetivo
          cost_real: true, // Precio Coste Real (No está el toggle)
          client_price: options.price, // Precio Cliente
          decomposition: options.decomposition, // Descomposición de partida
          measures: options.measures, // Mediciones
          comment: options.measures, // Comentarios
          debug: false, // iddqd
        }

        const res = await API.post(SERVICE_CORE_BASE, route, {
          headers: payload.headers,
          body: params,
        })

        const link = document.createElement("a")
        link.href = res.url
        link.setAttribute("download", name + extension) //or any other extension
        document.body.appendChild(link)
        link.click()

        amplitude.track({
          event_type: "Budget Excel Downloaded",
          event_properties: { projectId: projectId, projectName: name },
        })
        dispatch({
          type: "DOWNLOAD_BUDGET",
        })
      }
    } catch (error) {
      return {
        error: true,
        message: error?.message,
        function: "/project/download/" + projectId,
        service: "service-budget",
      }
    }
  }

  const downloadCertification = async (projectId, name) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          language: "es",
          currency: "EUR",
          cost_price: true,
          cost_real: true,
          client_price: true,
          decomposition: true,
          measures: true,
          comment: true,
          certifications: true,
          certification_id: "",
          debug: false,
        },
      }
      dispatch({
        type: "DOWNLOADING_BUDGET",
      })
      const response = await API.post(
        SERVICE_CORE_BASE,
        `/excel/s3/${projectId}`,
        payload,
      )

      const link = document.createElement("a")
      link.href = response.url
      link.download = `${name.replace(" ", "_")}.xlsx`
      document.body.appendChild(link)
      link.click()
      amplitude.track({
        event_type: "Certification Excel Downloaded",
        event_properties: { projectId: projectId, projectName: name },
      })
      dispatch({
        type: "DOWNLOAD_BUDGET",
      })
    } catch (error) {
      return {
        error: true,
        message: error?.message,
        function: "/project/excel/cert_download/" + projectId,
        service: "service-budget",
      }
    }
  }

  const _headers = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    return {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    }
  }

  const pdfApi = async (projectId, options) => {
    try {
      const res = await API.post(
        "service-budget",
        `/project/pdf/generate/${projectId}`,
        {
          headers: await _headers(),
          body: { options: { ...options, prices: options.price } },
        },
      )
      return res
    } catch (error) {
      return {
        error: true,
        function: `/project/download/${projectId}`,
        message: error?.message,
        service: "service-budget",
      }
    }
  }

  const pdfCore = async (user, projectId, options) => {
    try {
      const res = await API.post("CORE", `/pdf/request`, {
        headers: _headers(),
        body: {
          projectId: projectId,
          organizationId: user["custom:organizationId"],
          options: {
            ...options,
            currency: user["custom:currency"],
            language: user["custom:idioma"],
          },
        },
      })
      return res
    } catch (error) {
      return {
        error: true,
        function: `/pdf/request`,
        message: error?.message,
        service: "service-budget",
      }
    }
  }

  const pdfDownload = async (user, projectId, options) => {
    console.log(
      `pdfDownload: user=${JSON.stringify(
        user,
      )} projectId=${projectId} options=${JSON.stringify(options)}`,
    )
    if (options.mode === "legacy") {
      return pdfApi(projectId, options)
    } else {
      return pdfCore(user, projectId, options)
    }
  }

  const groupParts = async projectId => {
    dispatch({
      type: "GROUPING_PARTS",
    })
    let auxList = []
    let auxChapter = state.selectedPartGroup[0][0].chapterId
    let chapter = false
    let sameChapter = true
    for (let i = 0; i < state.selectedPartGroup.length; i++) {
      if (state.selectedPartGroup[i]) {
        if (state.selectedPartGroup[i].length > 0) {
          for (let j = 0; j < state.selectedPartGroup[i].length; j++) {
            if (state.selectedPartGroup[i][j].chapterId != auxChapter) {
              chapter = true
              sameChapter = false
            }
            auxList.push(state.selectedPartGroup[i][j])
          }
        }
      }
    }
    if (chapter == true) {
      auxChapter = "root"
    }
    let position = 0
    if (auxChapter === "root") {
      position = state.budgetTree.subcapitulos.length
    } else {
      let ch_tmp = state.budgetTree.getChapterById(auxChapter)
      position = ch_tmp.subcapitulos.length
    }

    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: {
        projectId: projectId,
        chapterParentId: auxChapter,
        parts: auxList,
        sameChapter: sameChapter,
        position: position,
      },
    }
    const res = await API.post("service-budget", "/group-parts", payload)
    cleanParts()
    //getBudgetTree(projectId, false)
    dispatch({
      type: "POST_GROUP_PARTS",
      payload: {
        parts: res.parts,
        chapter: res.chapter,
        oldParts: auxList,
      },
    })
  }

  const groupChapters = async projectId => {
    dispatch({
      type: "GROUPING_CHAPTERS",
    })
    let chaptersToGroup = state.budgetTree.getChaptersToGroup()
    let chapterParentId = chaptersToGroup[0].parentId
    let same = true
    let counter = 1
    while (same === true && counter < chaptersToGroup.length) {
      if (chaptersToGroup[counter].parentId === chapterParentId) {
        counter++
      } else {
        same = false
        chapterParentId = "root"
      }
    }

    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: {
        projectId: projectId,
        chapterParentId: chapterParentId,
        chapters: chaptersToGroup,
      },
    }

    const res = await API.post("service-budget", "/group-chapters", payload)

    let aux = []
    for (let index = 0; index < chaptersToGroup.length; index++) {
      aux.push(
        await getChapterById(
          chaptersToGroup[index].chapterId,
          state.budgetTree.subcapitulos,
        ),
      )
    }

    cleanChapters()
    dispatch({
      type: "POST_GROUP_CHAPTERS",
      payload: {
        chapterCreated: res.chapterCreated,
        newChapters: res.chapters,
        oldChapters: aux,
      },
    })
    //getBudgetTree(projectId, false)
  }

  const detectChange = () => {
    dispatch({
      type: "CHANGE_DIMENSIONS",
      payload: {
        num: state.changeSidebar,
      },
    })
  }

  const updatePartsList = partida => {
    let find = false
    let i = 0
    while (!find && i < state.partsChecked.length) {
      if (state.partsChecked[i].partidaId == partida.partidaId) {
        state.partsChecked.splice(i, 1)
        find = true
      } else {
        i++
      }
    }
    if (find == false) {
      state.partsChecked.push(partida)
    }

    dispatch({
      type: "UPDATE_PARTS_LIST",
      payload: {
        list: state.partsChecked,
      },
    })
  }

  const updateChaptersList = chapter => {
    let find = false
    let i = 0
    while (!find && i < state.chaptersChecked.length) {
      if (state.chaptersChecked[i].chapterId == chapter.chapterId) {
        state.chaptersChecked.splice(i, 1)
        find = true
      } else {
        i++
      }
    }
    if (find == false) {
      state.chaptersChecked.push(chapter)
    }
    dispatch({
      type: "UPDATE_CHAPTERS_LIST",
      payload: {
        list: state.chaptersChecked,
      },
    })

    dispatch({
      type: "UPDATE_PARTS_LIST",
      payload: {
        list: [],
      },
    })
  }

  const moveParts = (projectId, chapterId, position, imChecked) => {
    let pos = position
    let sameChapter = true
    if (state.imChecked.isChecked == false) {
      if (state.imChecked.partida.chapterId != chapterId) {
        sameChapter = false
      }
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId: state.imChecked.partida.chapterId,
          partidaId: state.imChecked.partida.partidaId,
        },
      })
      dispatch({
        type: "ADD_PARTIDA",
        payload: {
          chapterId,
          partidaNueva: state.imChecked.partida,
          index: pos,
        },
      })
    } else {
      for (let i = 0; i < state.partsChecked.length; i++) {
        if (state.partsChecked[i].chapterId != chapterId) {
          sameChapter = false
        }
        dispatch({
          type: "DEL_PARTIDA",
          payload: {
            capituloId: state.partsChecked[i].chapterId,
            partidaId: state.partsChecked[i].partidaId,
          },
        })
        state.partsChecked[i].checked = false
        dispatch({
          type: "ADD_PARTIDA",
          payload: {
            chapterId,
            partidaNueva: state.partsChecked[i],
            index: pos,
          },
        })
        pos = pos + 1
      }
    }
    dispatch({
      type: "CLEAN_STATE_FOR_MOVING_PARTS",
      payload: {
        objeto: { isChecked: false, partida: null },
      },
    })
    reorderParts(
      projectId,
      chapterId,
      state.imChecked.isChecked == false
        ? [state.imChecked.partida]
        : state.partsChecked,
      position,
      sameChapter,
    )
  }

  const changeCheck = objeto => {
    dispatch({
      type: "CHANGE_MOVING",
      payload: {
        res: objeto,
      },
    })
  }

  const changeCheck2 = objeto => {
    dispatch({
      type: "CHANGE_MOVING_2",
      payload: {
        res: objeto,
      },
    })
  }

  const moveChapters = async (projectId, chapterId, position, imChecked2) => {
    let pos = position
    let sameChapter = true
    let copiaSubCapitulos = state.budgetTree.subcapitulos
    if (state.imChecked2.isChecked == false) {
      if (state.imChecked2.capitulo.chapterId != chapterId) {
        sameChapter = false
      }
      dispatch({
        type: "DEL_CAPITULO",
        payload: state.imChecked2.capitulo.chapterId,
      })

      parent = await getChapterParentId(chapterId, copiaSubCapitulos)

      let aux = {
        chapter: {
          ...state.imChecked2.capitulo.capituloInfo,
          parentId: parent,
        },
      }
      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: parent,
          res: aux,
          indexCapitulo: pos,
        },
      })
    } else {
      for (let i = 0; i < state.chaptersChecked.length; i++) {
        if (state.chaptersChecked[i].chapterId != chapterId) {
          sameChapter = false
        }
        dispatch({
          type: "DEL_CAPITULO",
          payload: state.chaptersChecked[i].chapterId,
        })
        state.chaptersChecked[i].checked = false
        parent = await getChapterParentId(chapterId, copiaSubCapitulos)
        let aux = { chapter: { ...state.chaptersChecked[i], parentId: parent } }
        dispatch({
          type: "ADD_CAPITULO",
          payload: {
            parentId: parent,
            res: aux,
            indexCapitulo: pos,
          },
        })

        pos = pos + 1
      }
    }
    dispatch({
      type: "CLEAN_STATE_FOR_MOVING_CHAPTERS",
      payload: {
        objeto: { isChecked: false, capitulo: null },
      },
    })

    //reorderParts(projectId, chapterId, state.imChecked2.isChecked==false? [state.imChecked2.partida] : state.partsChecked, position, sameChapter)
  }

  const checkMultipleChapters = async chapterId => {
    let parent = await getChapterParentId(
      chapterId,
      state.budgetTree.subcapitulos,
    )
    let chapter = {}
    if (parent != "root") {
      chapter = await getChapterById(parent, state.budgetTree.subcapitulos)
    } else {
      chapter = state.budgetTree
    }

    if (chapter.subcapitulos.length > 0) {
      let indiceChapter = await getChapterIndex(chapter, chapterId)
      let i = 0
      let trobat1 = false
      let trobat2 = false
      while ((!trobat1 || !trobat2) && i < chapter.subcapitulos.length) {
        if (chapter.subcapitulos[i].chapterId != chapterId) {
          if (chapter.subcapitulos[i].checked == true) {
            if (i < indiceChapter) {
              for (let j = i + 1; j < indiceChapter + 1; j++) {
                dispatch({
                  type: "CHECK_CAPITULOS",
                  payload: {
                    capituloId: chapter.subcapitulos[j].chapterId,
                    value: true,
                  },
                })
                state.chaptersChecked.push(chapter.subcapitulos[j].capituloInfo)
                dispatch({
                  type: "UPDATE_CHAPTERS_LIST",
                  payload: {
                    list: state.chaptersChecked,
                  },
                })
              }
              trobat1 = true
              i = indiceChapter
            } else {
              let valorIni = indiceChapter
              for (let j = indiceChapter; j < i; j++) {
                if (j == valorIni) {
                  if (chapter.subcapitulos[valorIni].checked == false) {
                    dispatch({
                      type: "CHECK_CAPITULOS",
                      payload: {
                        capituloId: chapter.subcapitulos[j].chapterId,
                        value: true,
                      },
                    })
                    state.chaptersChecked.push(chapter.subcapitulos[j])
                  }
                } else {
                  dispatch({
                    type: "CHECK_CAPITULOS",
                    payload: {
                      capituloId: chapter.subcapitulos[j].chapterId,
                      value: true,
                    },
                  })
                  state.chaptersChecked.push(chapter.subcapitulos[j])
                }
              }
              trobat2 = true
              i++
            }
          } else {
            i++
          }
        } else {
          i++
        }
      }
    }
  }

  const checkMultipleParts = async (chapterId, part) => {
    let chapter = await getChapterById(chapterId, state.budgetTree.subcapitulos)
    if (chapter.partidas.length > 0) {
      //busqueda para el indice de la partida
      let indicePartida = await getPartIndex(chapter, part.partidaId)
      //busqueda para ver que partidas se han checkeado antes de (part)
      let i = 0
      let trobat1 = false
      let trobat2 = false
      while ((!trobat1 || !trobat2) && i < chapter.partidas.length) {
        if (chapter.partidas[i].partidaId != part.partidaId) {
          if (chapter.partidas[i].checked == true) {
            if (i < indicePartida) {
              for (let j = i + 1; j < indicePartida + 1; j++) {
                dispatch({
                  type: "CHECK_PARTIDA",
                  payload: {
                    capituloId: chapterId,
                    partidaId: chapter.partidas[j].partidaId,
                  },
                })
                state.partsChecked.push(chapter.partidas[j])
                dispatch({
                  type: "UPDATE_PARTS_LIST",
                  payload: {
                    list: state.partsChecked,
                  },
                })
              }
              trobat1 = true
              i = indicePartida
            } else {
              let valorIni = indicePartida
              for (let j = indicePartida; j < i; j++) {
                if (j == valorIni) {
                  if (chapter.partidas[valorIni].checked == false) {
                    dispatch({
                      type: "CHECK_PARTIDA",
                      payload: {
                        capituloId: chapterId,
                        partidaId: chapter.partidas[j].partidaId,
                      },
                    })
                    state.partsChecked.push(chapter.partidas[j])
                    dispatch({
                      type: "UPDATE_PARTS_LIST",
                      payload: {
                        list: state.partsChecked,
                      },
                    })
                  }
                } else {
                  dispatch({
                    type: "CHECK_PARTIDA",
                    payload: {
                      capituloId: chapterId,
                      partidaId: chapter.partidas[j].partidaId,
                    },
                  })
                  state.partsChecked.push(chapter.partidas[j])
                  dispatch({
                    type: "UPDATE_PARTS_LIST",
                    payload: {
                      list: state.partsChecked,
                    },
                  })
                }
              }
              trobat2 = true
              i++
            }
          } else {
            i++
          }
        } else {
          //esquivo la partida ya marcada y paso a la siguiente
          i++
        }
      }
    }
  }

  const addToSelfManagement = async (projectId, chapterId) => {
    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: {
        projectId: projectId,
        chapterId: chapterId,
      },
    }
    const res = await API.put("service-budget", "/addToSelfManagement", payload)
    getBudgetTree(projectId, false)
  }

  const reorderParts = async (
    projectId,
    chapterId,
    parts,
    position,
    sameChapter,
  ) => {
    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: {
        projectId: projectId,
        chapterId: chapterId, //Id de capitulo donde se mueven
        parts: parts, //Partidas a mover
        position: position, //Posicion a la que se mueven
        sameChapter: sameChapter, //true si se mueven al mismo capitulo en el que estaban
      },
    }
    const res = await API.post("service-budget", "/reorder-parts", payload)
  }

  const getTagsByProject = async () => {
    let auxTags = []
    for (let i = 0; i < state.budgetTree.subcapitulos.length; i++) {
      if (state.budgetTree.subcapitulos[i].capituloInfo.tags != undefined) {
        if (state.budgetTree.subcapitulos[i].capituloInfo.tags.length > 0) {
          for (
            let j = 0;
            j < state.budgetTree.subcapitulos[i].capituloInfo.tags.length;
            j++
          ) {
            auxTags.push(state.budgetTree.subcapitulos[i].capituloInfo.tags[j])
          }
        }
      }
    }

    dispatch({
      type: "UPDATE_TAGS_LIST",
      payload: {
        tagsList: auxTags,
      },
    })
  }

  const saveTagsToFilter = async tagsFiltered => {
    dispatch({
      type: "SAVE_TAGS_LIST",
      payload: {
        tagsList: tagsFiltered,
      },
    })
  }

  const closeBudgetVersion = async data => {
    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: data,
    }
    const res = await API.post("service-budget", "/saveBudget", payload)
  }

  const closeEntireBudget = async data => {
    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: data,
    }
    const res = await API.put("service-budget", "/closeEntireBudget", payload)
  }

  const changeVersion = async version => {
    dispatch({
      type: "CHANGE_VERSION",
      payload: {
        version: version,
      },
    })
  }

  const getVersionTree = async (version, projectId, soyFinal) => {
    try {
      dispatch({
        type: "LOADING_BUDGET",
        payload: {
          loading: true,
          budgetTree: new Chapter({
            capituloInfo: {
              chapterId: "root",
            },
            partidas: null,
            subcapitulos: null,
          }),
        },
      })

      if (soyFinal == true) {
        let response1 = await fetch(
          "https://cdn.tribboo.co/" + version.keyFinal,
        )
        let response = await response1.json()
        let subcapitulosAux = []
        for (let i = 0; i < response.length; i++) {
          let aux = new Chapter(response[i])
          subcapitulosAux.push(aux)
        }
        dispatch({
          type: "SET_BUDGET_TREE",
          payload: subcapitulosAux,
        })
      } else {
        let response1 = await fetch(
          "https://cdn.tribboo.co/" + version.keyNormal,
        )
        let response = await response1.json()
        let subcapitulosAux = []
        for (let capitulo of response) {
          let aux = new Chapter(capitulo)
          subcapitulosAux.push(aux)
        }
        dispatch({
          type: "SET_BUDGET_TREE",
          payload: subcapitulosAux,
        })
      }
    } catch (e) {
      dispatch({
        type: "ERR_BUDGET_TREE",
        payload: { loading: false },
      })
    }
  }

  const cleanParts = async () => {
    dispatch({
      type: "UPDATE_PARTS_LIST",
      payload: {
        list: [],
      },
    })
  }

  const cleanChapters = async () => {
    dispatch({
      type: "UPDATE_CHAPTERS_LIST",
      payload: {
        list: [],
      },
    })
  }

  const ExportToBankPrices = async (chapterDestination, parts, chapters) => {
    let partsIds = []
    let chaptersIds = []

    parts.map(part => {
      partsIds.push(part.partidaId)
    })

    chapters.map(chapter => {
      chaptersIds.push(chapter.chapterId)
    })

    let headers = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Access-Control-Allow-Origin": "*",
    }
    let body = {
      chapterDestination: chapterDestination.chapterId,
      chapterIds: chaptersIds,
      partIds: partsIds,
    }

    const res = await API.post("service-budget", "/export/bank-prices", {
      headers: headers,
      body: body,
    })
  }

  const createPartsOnBudget = async (
    accountId,
    parts,
    projectInfo,
    chapterId,
    capituloEntero,
    comeFrom,
  ) => {
    try {
      let auxChapterId
      if (chapterId == "root") {
        const path = "/chapter"
        let payload = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            "Access-Control-Allow-Origin": "*",
          },
          body: {
            name: "PARTIDAS SIN CLASIFICAR",
            description: "",
            parentId: chapterId,
            projectId: projectInfo.projectId,
            price: 0,
            tags: [],
            status: "OPEN",
            position: 0,
            accountId: accountId,
          },
        }
        const res = await API.post(SERVICE_BUDGET_TEMP, path, payload)
        auxChapterId = res.chapterId
      } else {
        auxChapterId = chapterId
      }
      for (let i = 0; i < parts.length; i++) {
        let decomposition = []
        if (parts[i].desglose) {
          Object.keys(parts[i].desglose).forEach(key => {
            decomposition.push({
              description: parts[i].desglose[key]["RESUMEN"],
              quantity: parseFloat(
                parseFloat(parts[i].desglose[key]["RENDIMIENTO"]).toFixed(2),
              ),
              price: parseFloat(
                parseFloat(parts[i].desglose[key]["PRECIO"]["E"]).toFixed(2),
              ),
              type: parseInt(parts[i].desglose[key]["TIPO"]),
              unity: parts[i].desglose[key]["UNIDAD"],
            })
          })
        } else if (parts[i].decomposition) {
          parts[i].decomposition.forEach(item => decomposition.push(item))
        }
        let payload = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            "Access-Control-Allow-Origin": "*",
          },
          body: {
            chapterId: auxChapterId,
            code: parts[i].code,
            name: parts[i].name,
            description: parts[i].description,
            quantity: 0,
            unity: parts[i].unity ? parts[i].unity : "pa",
            userPrice: parts[i].userPrice
              ? parseFloat(parts[i].userPrice)
              : parseFloat(parts[i].price),
            price: parseFloat(
              parts[i].userPrice ? parts[i].userPrice : parts[i].price,
            ),
            costeGeneral: projectInfo.costeGeneral,
            costeIndirecto: projectInfo.costeIndirecto,
            industrialProfit: projectInfo.industrialProfit,
            projectId: projectInfo.projectId,
            status: "OPEN",
            position: chapterId == "root" ? i : capituloEntero.partidas.length,
            elementInfo: parts[i].elementInfo ? parts[i].elementInfo : null,
            comeFrom: comeFrom,
            accountId: accountId,
            decomposition: decomposition,
            refPrice: parseFloat(parts[i].price),
            clientPrice: 0,
          },
        }
        const partida = await API.post(SERVICE_BUDGET_TEMP, "/part", payload)

        let aux = {
          partidaId: partida,
          elementInfo: partida.elementInfo ? partida.elementInfo : null,
          chapterId: chapterId,
        }

        let tmpIndex = partida.position
        let partidaNueva = new Part(aux)
        dispatch({
          type: "ADD_PARTIDA",
          payload: {
            chapterId,
            partidaNueva,
            tmpIndex: tmpIndex + 1,
          },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const exportBudget2BC3 = async (projectId, name) => {
    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    const res = await API.post(
      "service-budget",
      "/project/export/bc3/" + projectId,
      payload,
    )
    const extension = ".bc3"
    axios({
      url: res,
      method: "GET",
      responseType: "blob",
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", name + extension) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  const getBudgetPDF = async projectId => {
    try {
      dispatch({
        type: "LOADING_BUDGET_PDF",
      })
      let auxCaps = []
      for (let i = 0; i < state.chaptersChecked.length; i++) {
        auxCaps.push(state.chaptersChecked[i].chapterId)
      }
      let route = "/project/download/" + projectId
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      let body = {
        chapters: auxCaps,
        typeDocument: "pdf",
        preview: true,
        objectivePrice: true,
        typeBudget: "cost",
        options: {
          price: true,
          client: false,
          company: true,
          description: true,
          cover: true,
        },
      }
      const res = await API.post("service-budget", route, {
        headers: payload.headers,
        body: body,
      })

      dispatch({
        type: "SET_BUDGET_PDF",
        payload: res.url,
      })
      return res
    } catch (e) {
      console.log(e)
    }
  }
  const updateFinalPrice = price => {
    dispatch({
      type: "UPDATE_FINALPRICE",
      payload: price,
    })
  }

  const recalcBudget = async projectId => {
    try {
      dispatch({
        type: "ON_UPDATE_RECALC",
      })
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      //await API.put(SERVICE_BUDGET, '/recalculate/cost/'+projectId, payload)
      //await API.put(SERVICE_BUDGET, '/recalculate/client/'+projectId, payload)
      await API.put(SERVICE_BUDGET, "/recalculate/" + projectId, payload)
      dispatch({
        type: "RECALC_DONE",
      })
    } catch (error) {
      console.log(error)
    }
  }

  const moveChaptersWithArrows = async (projectId, chapter, movement) => {
    const chapterToMove = chapter
    var chapterToChange = {}
    if (movement == "up") {
      chapterToChange = await getChapterByParentAndPosition(
        chapterToMove.parentId,
        state.budgetTree.subcapitulos,
        chapterToMove.capituloInfo.position - 1,
        false,
        0,
        {},
      )
      dispatch({
        type: "DEL_CAPITULO",
        payload: { capituloId: chapterToChange.chapterId },
      })
      dispatch({
        type: "DEL_CAPITULO",
        payload: { capituloId: chapterToMove.chapterId },
      })
      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: chapterToMove.parentId,
          res: {
            ...chapterToMove,
            capituloInfo: chapterToMove,
            position: chapterToChange.capituloInfo.position,
          },
          indexCapitulo: chapterToChange.capituloInfo.position,
        },
      })
      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: chapterToChange.parentId,
          res: {
            ...chapterToChange,
            capituloInfo: chapterToChange,
            position: chapterToMove.capituloInfo.position,
          },
          indexCapitulo: chapterToMove.capituloInfo.position,
        },
      })
    } else {
      chapterToChange = await getChapterByParentAndPosition(
        chapterToMove.parentId,
        state.budgetTree.subcapitulos,
        chapterToMove.capituloInfo.position + 1,
        false,
        0,
        {},
      )
      dispatch({
        type: "DEL_CAPITULO",
        payload: { capituloId: chapterToChange.chapterId },
      })
      dispatch({
        type: "DEL_CAPITULO",
        payload: { capituloId: chapterToMove.chapterId },
      })
      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: chapterToChange.parentId,
          res: {
            ...chapterToChange,
            capituloInfo: chapterToChange,
            position: chapterToMove.capituloInfo.position,
          },
          indexCapitulo: chapterToMove.capituloInfo.position,
        },
      })
      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: chapterToMove.parentId,
          res: {
            ...chapterToMove,
            capituloInfo: chapterToMove,
            position: chapterToChange.capituloInfo.position,
          },
          indexCapitulo: chapterToChange.capituloInfo.position,
        },
      })
    }
    try {
      const position = movement === "down" ? 1 : -1
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          chapterId: chapterToMove.chapterId,
          position: position,
        },
      }
      await API.patch(SERVICE_BUDGET, "/chapter/position", payload)
    } catch (error) {
      console.log(error)
    }
  }

  const movePartsWithArrows = async (projectId, part, movement) => {
    const partToMove = part
    var partToChange = {}
    if (movement == "up") {
      partToChange = await getPreviousPartByParentAndPosition(
        partToMove.chapterId,
        state.budgetTree.subcapitulos,
        partToMove.position,
        false,
        0,
        {},
      )
      dispatch({
        type: "DEL_PARTIDA_MOVE",
        payload: {
          capituloId: partToChange.chapterId,
          partidaId: partToChange.partidaId,
        },
      })
      dispatch({
        type: "DEL_PARTIDA_MOVE",
        payload: {
          capituloId: partToMove.chapterId,
          partidaId: partToMove.partidaId,
        },
      })
      let partToMovePosition = partToMove.position
      let partToChangePosition = partToChange.position

      dispatch({
        type: "ADD_PARTIDA_MOVE",
        payload: {
          chapterId: partToMove.chapterId,
          partidaNueva: partToMove,
          tmpIndex: partToChangePosition,
        },
      })
      dispatch({
        type: "ADD_PARTIDA_MOVE",
        payload: {
          chapterId: partToChange.chapterId,
          partidaNueva: partToChange,
          tmpIndex: partToMovePosition,
        },
      })
    } else {
      partToChange = await getNextPartByParentAndPosition(
        partToMove.chapterId,
        state.budgetTree.subcapitulos,
        partToMove.position,
        false,
        0,
        {},
      )
      dispatch({
        type: "DEL_PARTIDA_MOVE",
        payload: {
          capituloId: partToChange.chapterId,
          partidaId: partToChange.partidaId,
        },
      })
      dispatch({
        type: "DEL_PARTIDA_MOVE",
        payload: {
          capituloId: partToMove.chapterId,
          partidaId: partToMove.partidaId,
        },
      })
      let partToMovePosition = partToMove.position
      let partToChangePosition = partToChange.position
      dispatch({
        type: "ADD_PARTIDA_MOVE",
        payload: {
          chapterId: partToChange.chapterId,
          partidaNueva: partToChange,
          tmpIndex: partToMovePosition,
        },
      })
      dispatch({
        type: "ADD_PARTIDA_MOVE",
        payload: {
          chapterId: partToMove.chapterId,
          partidaNueva: partToMove,
          tmpIndex: partToChangePosition,
        },
      })
    }
    try {
      let parentChapter = await getChapterById(
        part.chapterId,
        state.budgetTree.subcapitulos,
      )
      const position = movement === "down" ? 1 : -1
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          partId: partToMove.partidaId,
          position: position,
        },
      }
      await API.patch(SERVICE_BUDGET, "/part/position", payload)
    } catch (error) {
      console.log(error)
    }
  }

  const exportToTemplate = async (
    chapterDestination,
    chapters,
    parts,
    templateId,
  ) => {
    let partsIds = []
    let chaptersIds = []

    parts.map(part => {
      partsIds.push(part.partidaId)
    })

    chapters.map(chapter => {
      chaptersIds.push(chapter.chapterId)
    })

    let headers = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Access-Control-Allow-Origin": "*",
    }
    let body = {
      chapterDestination: chapterDestination.chapterId
        ? chapterDestination.chapterId
        : "root",
      chapterIds: chaptersIds,
      partIds: partsIds,
      templateDestination: templateId,
    }

    const res = await API.post("service-budget", "/export/template", {
      headers: headers,
      body: body,
    })
  }

  const sendBudget = async ({
    message,
    contact,
    projectId,
    budgetTree,
    tipo,
    mediciones,
    precioObjetivo,
    options,
  }) => {
    //adaptar llamada para options
    try {
      dispatch({
        type: SENDING_MESSAGE,
        payload: message,
      })

      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const template = await API.get(
        "service-budget",
        "/documentTemplates/default-budget-pdf",
        payload,
      )

      if (Object.keys(template).length === 0) {
        const body = {
          Message: message,
          toAddress: contact.email,
          name: contact.completeName,
          contactInfo: contact,
          projectId: projectId,
          budgetTree: budgetTree,
          mediciones: mediciones,
          hasExcel:
            tipo.length == 1 ? (tipo[0] === "excel" ? true : false) : true,
          hasPdf: tipo.length == 1 ? (tipo[0] === "PDF" ? true : false) : true,
          precioObjetivo: precioObjetivo,
          Budget: {
            capitulos: state.sendChapters.map(capitulo => {
              return {
                nombreCapitulo: capitulo.nombreCapitulo,
                partidas: capitulo.partidas.map(partida => {
                  return {
                    codigo: partida.codigo,
                    cantidad: partida.cantidad,
                    precioUsuario: partida.precioUsuario,
                  }
                }),
              }
            }),
          },
        }
        const headers = {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        }
        let res

        res = await API.post("service-budget", "/sendBudgetExcelAndPdf", {
          headers: headers,
          body: body,
        })
        dispatch({
          type: SEND_COMPLETE,
          payload: message,
        })

        return res
      } else {
        const body = {
          Message: message,
          toAddress: contact.email,
          name: contact.completeName,
          contactInfo: contact,
          projectId: projectId,
          budgetTree: budgetTree,
          mediciones: mediciones,
          precioObjetivo: precioObjetivo,
          template: template.documentTemplateId,
          hasExcel:
            tipo.length == 1 ? (tipo[0] === "excel" ? true : false) : true,
          hasPdf: tipo.length == 1 ? (tipo[0] === "PDF" ? true : false) : true,
          Budget: {
            capitulos: state.sendChapters.map(capitulo => {
              return {
                nombreCapitulo: capitulo.nombreCapitulo,
                partidas: capitulo.partidas.map(partida => {
                  return {
                    codigo: partida.codigo,
                    cantidad: partida.cantidad,
                    precioUsuario: partida.precioUsuario,
                  }
                }),
              }
            }),
          },
        }
        const headers = {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        }
        let res

        res = await API.post("service-budget", "/sendBudgetExcelAndPdf", {
          headers: headers,
          body: body,
        })

        dispatch({
          type: SEND_COMPLETE,
          payload: message,
        })

        return res
      }
    } catch (e) {
      return {
        error: true,
        message: e?.message,
        function: "/sendBudgetExcelAndPdf",
        service: "service-budget",
      }
    }
  }

  const reset = () => {
    dispatch({
      type: RESET_SEND,
    })
  }

  const copyBudgetData = data => {
    dispatch({
      type: "COPY_DATA",
      payload: data,
    })
  }

  const undoAction = async () => {
    //la accion se guarda directamente como la inversa
    //ejemplo al crear un capitulo se guardara la accion como eliminar capitulo
    //en esta funcion solo hay que realizar la accion y actualizar la pila
    console.log("eeeee", state.lastActions[state.lastActions.length - 1])
    if (
      state.lastActions[state.lastActions.length - 1].action == "DELETE_CHAPTER"
    ) {
      let capituloId =
        state.lastActions[state.lastActions.length - 1].payload.capituloId
      let projectId =
        state.lastActions[state.lastActions.length - 1].payload.projectId
      await deleteCapitulo(capituloId, projectId)
      dispatch({
        type: "REMOVE_LAST_UNDO_ACTION",
      })
    }
  }
  const deletePartDecomposition = async (partida, recoverBool) => {
    try {
      dispatch({
        type: "ON_UPDATE",
      })
      let headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.post(
        SERVICE_BUDGET,
        "/part/setDecomposition/" + partida.partidaId,
        { headers: headers, body: { decomposition: recoverBool } },
      )
      const partidaUpdated2 = {
        partidaId: res.partida,
        elementInfo: null,
      }
      console.log(partidaUpdated2)
      dispatch({
        type: "UPDATE_PARTIDA",
        payload: {
          newData: partidaUpdated2,
          capituloId: partidaUpdated2.partidaId.chapterId,
          final: false,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const setBc3LinkData = data => {
    dispatch({
      type: SET_BC3_LINK_DATA,
      payload: data,
    })
  }

  const toggleModalImportPartFromBc3Link = isOpen => {
    dispatch({
      type: TOGGLE_MODAL_IMPORT_BC3_LINK,
      payload: isOpen,
    })
  }

  const setIsImportingPartFromEditModal = isEditing => {
    dispatch({
      type: IS_IMPORTING_PART_FROM_EDIT_MODAL,
      payload: isEditing,
    })
  }

  const setIsContradictingPart = isEditing => {
    dispatch({
      type: IS_CONTRADICTING_PART,
      payload: isEditing,
    })
  }

  const setModalBudgetingError = data => {
    dispatch({
      type: SET_MODAL_BUDGETING_ERROR,
      payload: data,
    })
  }

  const groupByChapterId = parts => {
    const groups = {}
    parts.forEach(part => {
      const chapterId = part.chapterId
      if (!groups[chapterId]) {
        groups[chapterId] = []
      }
      groups[chapterId].push(part)
    })
    return groups
  }

  const saveAndRefreshDuplicatedParts = async (
    projectId,
    chapterId,
    partsIds,
    headers,
  ) => {
    try {
      const { parts } = await API.post(SERVICE_PART_CORE, "/duplicate", {
        headers: headers,
        body: {
          projectID: projectId,
          chapterID: chapterId,
          partIDs: partsIds,
        },
      })

      dispatch({
        type: REMOVE_ALL_PARTS_FROM_CHAPTER,
        payload: {
          chapterId: chapterId,
        },
      })

      parts.forEach(p => {
        dispatch({
          type: "ADD_PARTIDA",
          payload: {
            chapterId: p.chapterId,
            partidaNueva: new Part({ partidaId: p }),
            tmpIndex: p.position + 1,
          },
        })
      })
      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
      cleanParts()
      cleanChapters()
    } catch (error) {
      console.log(error)
      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
      cleanParts()
      cleanChapters()
    }
  }

  const duplicateParts = async parts => {
    dispatch({
      type: "ON_UPDATE",
    })

    const headers = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Access-Control-Allow-Origin": "*",
    }

    const { projectId } = parts[0]

    const groupedParts = groupByChapterId(parts)

    for (const chapterId in groupedParts) {
      if (groupedParts.hasOwnProperty(chapterId)) {
        const partidas = groupedParts[chapterId]
        const partsIds = partidas.map(p => p.partidaId)

        saveAndRefreshDuplicatedParts(projectId, chapterId, partsIds, headers)
      }
    }
  }

  const setChapterIdSelectedFromBc3Link = async chapterId => {
    dispatch({
      type: SET_CHAPTER_ID_SELECTED_FROM_BC3LINK,
      payload: {
        chapterId: chapterId,
      },
    })
  }

  const exportToWord = async (partsIds, chaptersIds) => {
    try {
      let commonHeaders = getCommonHeaders()

      const body = {
        partsIds: partsIds,
        chaptersIds: chaptersIds,
      }

      const payload = {
        ...commonHeaders,
        body: body,
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_ICON,
          payload: {
            isLoading: false,
            index: null,
          },
        })
        cleanParts()
        cleanChapters()
      }, 6000)
      const resp = await API.post(SERVICE_CORE_BASE, "/exportPdf", payload)
    } catch (error) {
      console.log(error)
      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
      cleanParts()
      cleanChapters()
    }
  }

  const exportToExcel = async (partsIds, chaptersIds) => {
    try {
      let commonHeaders = getCommonHeaders()
      const path =
        partsIds.length > 0 ? "/excel/s3/partidas" : "/excel/s3/chapters"

      const bodyStructure = {
        chapters: [],
        partidas: [],
        options: {
          cost_price: true,
          cost_real: true,
          client_price: true,
          decomposition: true,
          measures: true,
          comment: true,
          certifications: false,
          certification_id: "",
          debug: false,
        },
      }
      const body =
        partsIds.length > 0
          ? {
              ...bodyStructure,
              partidas: partsIds.map(p => p.partidaId),
            }
          : {
              ...bodyStructure,
              chapters: chaptersIds.map(c => c.chapterId),
            }

      const payload = {
        ...commonHeaders,
        body: body,
      }
      const resp = await API.post(SERVICE_CORE_BASE, path, payload)
      const link = document.createElement("a")
      link.style.display = "none"
      link.href = resp.url
      document.body.appendChild(link)
      link.click()

      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
      cleanParts()
      cleanChapters()
    } catch (error) {
      console.log(error)
      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
      cleanParts()
      cleanChapters()
    }
  }
  const exportToPdf = async (partsIds, chaptersIds) => {
    try {
      let commonHeaders = getCommonHeaders()

      const hasParts = partsIds && partsIds.length > 0
      const hasChapters = chaptersIds && chaptersIds.length > 0

      const commonOptions = {
        briefing: {
          position: "top",
          depth: "chapters",
        },
        cover: true,
        conditions: false,
        currency: "EUR",
        decomposition: true,
        description: true,
        comment: true,
        envelope: true,
        footer: true,
        header: "simple",
        header_date: false,
        language: "es",
        logo: true,
        measures: true,
        mode: "price",
        price: "blind",
        signature: true,
        certificationId: "",
      }

      let body
      let url

      if (hasParts) {
        body = {
          partidas: partsIds.map(p => p.partidaId),
          body: {
            options: commonOptions,
          },
        }
        url = `${partsIds[0].projectId}/partidas`
      } else if (hasChapters) {
        body = {
          chapters: chaptersIds.map(c => c.chapterId),
          body: {
            options: commonOptions,
          },
        }
        url = `${chaptersIds[0].projectId}/chapters`
      } else {
        throw new Error("Neither partsIds nor chaptersIds have data.")
      }

      const payload = {
        ...commonHeaders,
        body: body,
      }

      setTimeout(() => {
        dispatch({
          type: SET_LOADING_ICON,
          payload: {
            isLoading: false,
            index: null,
          },
        })
        cleanParts()
        cleanChapters()
      }, 6000)

      const resp = await API.post(
        SERVICE_CORE_BASE,
        `/pdf/project/s3/${url}`,
        payload,
      )

      const link = document.createElement("a")
      link.href = resp.url
      link.download = "archivo.pdf"
      link.click()
    } catch (error) {
      console.log(error)
      dispatch({
        type: SET_LOADING_ICON,
        payload: {
          isLoading: false,
          index: null,
        },
      })
      cleanParts()
      cleanChapters()
    }
  }

  const movePartsToChapter = async (partsChecked, chapterId, projectId) => {
    try {
      let commonHeaders = getCommonHeaders()
      const partsIds = partsChecked.map(part => part.partidaId)
      const body = {
        partIDs: partsIds,
        dstProjectID: projectId,
        dstChapterID: chapterId,
        move: true,
      }

      const payload = {
        ...commonHeaders,
        body: body,
      }

      const response = await API.post(
        SERVICE_CORE_BASE,
        "/partida/move_to",
        payload,
      )
      partsChecked.map(part => {
        dispatch({
          type: "ON_UPDATE",
        })
        dispatch({
          type: "DEL_PARTIDA",
          payload: {
            capituloId: part.chapterId,
            partidaId: part.partidaId,
          },
        })
      })
      dispatch({
        type: "ON_UPDATE",
      })
      dispatch({
        type: "DEL_CAPITULO",
        payload: { capituloId: response.chapterId },
      })
      dispatch({
        type: "ON_UPDATE",
      })
      const capituloInfo = {
        ...response,
        price: response.partidas.reduce(
          (acc, part) => acc + part.price * part.quantity,
          0,
        ),
        clientPrice: response.partidas.reduce(
          (acc, part) => acc + part.clientPrice * part.quantity,
          0,
        ),
        clientPriceWithoutDiscount: response.partidas.reduce(
          (acc, part) => acc + part.clientPriceWithoutDiscount * part.quantity,
          0,
        ),
      }
      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: response.parentId,
          res: {
            ...response,
            capituloInfo: capituloInfo,
            price: response.partidas.reduce(
              (acc, part) => acc + part.price * part.quantity,
              0,
            ),
            clientPrice: response.partidas.reduce(
              (acc, part) => acc + part.clientPrice * part.quantity,
              0,
            ),
          },
          indexCapitulo: response.position,
        },
      })
      dispatch({
        type: "CLEAN_PARTS",
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setLoadingIcon = data => {
    dispatch({
      type: SET_LOADING_ICON,
      payload: data,
    })
  }

  const closeBudgetExtras = async projectId => {
    dispatch({
      type: "ON_UPDATE",
    })
    try {
      let commonHeaders = getCommonHeaders()
      const payload = {
        ...commonHeaders,
      }
      const path = `/projects/${projectId}/close`
      const response = await API.get(SERVICE_CORE_BASE, path, payload)

      setIsBudgetClosed(response.is_closed)
    } catch (error) {
      console.log(error)
    }
  }

  const openBudgetExtras = async projectId => {
    dispatch({
      type: "ON_UPDATE",
    })
    try {
      let commonHeaders = getCommonHeaders()
      const payload = {
        ...commonHeaders,
      }
      const path = `/projects/${projectId}/open`
      const response = await API.get(SERVICE_CORE_BASE, path, payload)

      setIsBudgetClosed(response.is_closed)
    } catch (error) {
      console.log(error)
    }
  }

  const setIsBudgetClosed = isBudgetClosed => {
    dispatch({
      type: IS_BUDGET_CLOSED,
      payload: isBudgetClosed,
    })
  }

  const setDynamicElementModalIsOpen = isOpen => {
    dispatch({
      type: SET_DYNAMIC_ELEMENT_MODAL_IS_OPEN,
      payload: isOpen,
    })
  }

  const setDynamicElementSelected = itemsSelected => {
    dispatch({
      type: SET_DYNAMIC_ELEMENT_SELECTED,
      payload: itemsSelected,
    })
  }

  const setDynamicElementProjectId = projectId => {
    dispatch({
      type: SET_DYNAMIC_ELEMENT_PROJECT_ID,
      payload: projectId,
    })
  }

  const setGeneralUpdate = update => {
    dispatch({
      type: SET_GENERAL_UPDATE,
      payload: update,
    })
  }

  return (
    <Budget2023Context.Provider
      value={{
        budgetTree: state.budgetTree,
        extraAndContradictions: state.extraAndContradictions,
        setExtraAndContraditions,
        dynamicElement: {
          modalIsOpen: state.dynamicElement.modalIsOpen,
          setModalIsOpen: setDynamicElementModalIsOpen,
          selected: state.dynamicElement.selected,
          setSelected: setDynamicElementSelected,
          projectId: state.dynamicElement.projectId,
          setProjectId: setDynamicElementProjectId,
        },
        recalcBudget,
        movePartsWithArrows,
        deletePartDecomposition,
        loadingRecalc: state.loadingRecalc,
        loadingTree: state.loading,
        updating: state.updating,
        readyToClose: state.readyToClose,
        urlDownload: state.urlDownload,
        getBudgetTree,
        deleteCapitulo,
        deleteCapituloAndSetPosition,
        updateFinalPrice,
        deletePartidav2,
        createPartidaV2,
        addCapituloV2,
        updateIsExpanded,
        updatePartidav2,
        updateCapitulov2,
        checkCapitulos,
        checkPartida,
        uncheckAll,
        closeBudget,
        isUpdates,
        downloadBudget,
        updatePreciosFinalesv2,
        addImageToPart,
        deleteImageFromPart,
        selectedBudget2: state.selectedBudget2,
        selectedPartGroup: state.selectedPartGroup,
        errorAgrupar: state.errorAgrupar,
        getSelectedBudget,
        selectPartsGroup,
        ExportToBankPrices,
        groupParts,
        changeSidebar: state.changeSidebar,
        detectChange,
        selectedChaptersGroup: state.selectedChaptersGroup,
        getSelectedBudget2,
        groupChapters,
        updatePartsList,
        partsChecked: state.partsChecked,
        chaptersChecked: state.chaptersChecked,
        moveParts,
        changeCheck,
        imChecked: state.imChecked,
        imChecked2: state.imChecked2,
        checkMultipleParts,
        checkMultipleChapters,
        addToSelfManagement,
        getTagsByProject,
        tags: state.tags,
        saveTagsToFilter,
        tagsFiltered: state.tagsFiltered,
        closeBudgetVersion,
        closeEntireBudget,
        changeVersion,
        versionToDisplay: state.versionToDisplay,
        getVersionTree,
        updateChaptersList,
        changeCheck2,
        moveChapters,
        getSelectedChapters,
        cleanParts,
        cleanChapters,
        createPartsOnBudget,
        getSubCapitulosContent,
        getSubCapitulosContent2,
        exportBudget2BC3,
        getBudgetPDF,
        budgetPDF: state.budgetPDF,
        loadingBudgetPDF: state.loadingBudgetPDF,
        deleteMultipleParts,
        moveChaptersWithArrows,
        exportToTemplate,
        preferencias: state.preferencias,
        setPreferencias,
        sendBudget,
        loadingSend: state.loadingSend,
        sendOk: state.sendOk,
        reset,
        loadingAgruparChapters: state.loadingAgruparChapters,
        loadingAgruparParts: state.loadingAgruparParts,
        copiedData: state.copiedData,
        copyBudgetData,
        lastActions: state.lastActions,
        undoAction,
        pdfDownload,
        closeCertification,
        updateCurrentPercentageChapterCertification,
        bc3LinkData: state.bc3LinkData,
        setBc3LinkData,
        toggleModalImportPartFromBc3Link,
        openModalImportPartFromBc3Link: state.openModalImportPartFromBc3Link,
        setIsImportingPartFromEditModal,
        isImportingPartFromEditModal: state.isImportingPartFromEditModal,
        setModalBudgetingError,
        modalBudgetingError: state.modalBudgetingError,
        duplicateParts,
        downloadCertification,
        chapterIdSelectedFromBc3Link: state.chapterIdSelectedFromBc3Link,
        setChapterIdSelectedFromBc3Link,
        setIsContradictingPart,
        isContradictingPart: state.isContradictingPart,
        loadingIcon: state.loadingIcon,
        setLoadingIcon,
        exportToPdf,
        exportToWord,
        exportToExcel,
        movePartsToChapter,
        closeBudgetExtras,
        openBudgetExtras,
        isBudgetClosed: state.isBudgetClosed,
        setIsBudgetClosed,
        certification: {
          generalUpdate: state.certification.generalUpdate,
          setGeneralUpdate: setGeneralUpdate,
          loading: state.certification.loading,
        },
      }}
    >
      {props.children}
    </Budget2023Context.Provider>
  )
}
Budget2023State.propTypes = {
  children: PropTypes.any.isRequired,
}
export default Budget2023State
