import { isNaN, isNil } from "lodash"

const calculateCertPreviousPrice = (data, oThis) => {
  const result = !isNil(data.partidaId.cert_previous_price)
    ? data.partidaId.cert_previous_price
    : oThis.cert_previous * oThis.clientPrice

  return isNaN(result) ? 0 : result
}

const getClientPrice = partidaId => {
  const result = partidaId.clientPrice ?? partidaId.finalPrice ?? 0
  return isNaN(result) ? 0 : result
}

class Part {
  constructor(data) {
    //FROM DATABASE
    this.partidaId = data.partidaId.partidaId
    this.accountId = data.partidaId.accountId
    this.discount = data.partidaId.discount
    this.decomposition = data.partidaId.decomposition
    this.contradicted_by_id = isNil(data.partidaId?.contradicted_by_id)
      ? null
      : data.partidaId.contradicted_by_id
    this.comment = data.partidaId.comment
    this.refPrice =
      data.partidaId.refPrice != null || data.partidaId.refPrice != undefined
        ? data.partidaId.refPrice % 1 === 0
          ? data.partidaId.refPrice
          : //: parseFloat(data.partidaId.refPrice).toFixed(2)
            parseFloat(data.partidaId.refPrice)
        : 0 //deprecated
    this.projectId = data.partidaId.projectId
    this.price =
      data.partidaId.price != null || data.partidaId.price != undefined
        ? data.partidaId.price % 1 === 0
          ? data.partidaId.price
          : //: parseFloat(data.partidaId.price).toFixed(2)
            parseFloat(data.partidaId.price)
        : 0
    this.quantity =
      data.partidaId.quantity != null || data.partidaId.quantity != undefined
        ? data.partidaId.quantity % 1 === 0
          ? data.partidaId.quantity
          : //: parseFloat(data.partidaId.quantity).toFixed(2)
            parseFloat(data.partidaId.quantity)
        : 0
    this.updatedAt = data.partidaId.updatedAt
    this.createdAt = data.partidaId.createdAt
    this.code = data.partidaId.code // to use
    this.description = data.partidaId.description
    this.chapterId = data.partidaId.chapterId
    this.name = data.partidaId.name
    this.elementInfo = data.elementInfo
      ? Object.keys(data.elementInfo).length > 0
        ? data.elementInfo
        : null
      : null //deprecated
    this.parentId = data.partidaId.chapterId
    this.unity = data.partidaId.unity ? data.partidaId.unity : ""
    this.status = "OPEN" //deprecated
    this.updates = data.partidaId.updates ? data.partidaId.updates : null //deprecated
    this.images = data.partidaId.images ? data.partidaId.images : null
    this.attachedFiles = data.partidaId.attachedFiles
      ? data.partidaId.attachedFiles
      : null //deprecated
    this.comeFrom = data.partidaId.comeFrom ? data.partidaId.comeFrom : null
    this.position = data.partidaId.position ? data.partidaId.position : 0
    this.measures = data.partidaId.measures ? data.partidaId.measures : []
    this.costeGeneral = data.partidaId.costeGeneral
      ? data.partidaId.costeGeneral
      : 0
    this.costeIndirecto = data.partidaId.costeIndirecto
      ? data.partidaId.costeIndirecto
      : 0
    this.industrialProfit = data.partidaId.industrialProfit
      ? data.partidaId.industrialProfit
      : 0
    this.files = data.partidaId.files ? data.partidaId.files : []
    this.cert_current = data.partidaId.cert_current
      ? data.partidaId.cert_current
      : 0
    this.clientPrice = getClientPrice(data.partidaId)
    this.cert_previous = data.partidaId.cert_previous
      ? data.partidaId.cert_previous
      : 0
    this.cert_previous_price = calculateCertPreviousPrice(data, this)
    this.clientPriceWithoutDiscount = data.partidaId.clientPriceWithoutDiscount
      ? data.partidaId.clientPriceWithoutDiscount
      : data.partidaId.finalPriceWithoutDiscount
        ? data.partidaId.finalPriceWithoutDiscount
        : 0
    //ONLY FOR FRONTEND
    this.creadaEnFinal = data.creadaEnFinal ? data.creadaEnFinal : false
    this.empty =
      this.code === "" || this.description === "" || this.code === undefined
    this.checked = false
    this.decompositionHiden = data.partidaId._decomposition
      ? data.partidaId._decomposition
      : []
    this.costReal = data.partidaId.costReal ? data.partidaId.costReal : null
  }

  updatePartida(data) {
    console.log(`updatePartida ${data}`)
    try {
      //FROM DATABASE
      this.partidaId = data.partidaId.partidaId
      this.discount = data.partidaId.discount
        ? data.partidaId.discount
        : data.discount
          ? data.discount
          : 0
      this.costeGeneral = data.partidaId.costeGeneral
        ? data.partidaId.costeGeneral
        : 0
      this.costeIndirecto = data.partidaId.costeIndirecto
        ? data.partidaId.costeIndirecto
        : 0
      this.industrialProfit = data.partidaId.industrialProfit
        ? data.partidaId.industrialProfit
        : 0
      this.refPrice =
        data.partidaId.refPrice != null || data.partidaId.refPrice != undefined
          ? data.partidaId.refPrice % 1 === 0
            ? data.partidaId.refPrice
            : //: data.partidaId.refPrice.toFixed(2)
              data.partidaId.refPrice
          : 0
      this.costReal = data.partidaId.costReal ? data.partidaId.costReal : null
      this.accountId = data.partidaId.accountId
      this.decomposition = data.partidaId.decomposition
      this.comment = data.partidaId.comment
      this.projectId = data.partidaId.projectId
      this.price =
        data.partidaId.price != null || data.partidaId.price != undefined
          ? data.partidaId.price % 1 === 0
            ? data.partidaId.price
            : //: data.partidaId.price.toFixed(2)
              data.partidaId.price
          : 0
      this.clientPrice = data.partidaId.clientPrice
        ? data.partidaId.clientPrice
        : data.partidaId.finalPrice
          ? data.partidaId.finalPrice
          : 0
      this.quantity = data.partidaId.quantity
        ? data.partidaId.quantity % 1 === 0
          ? data.partidaId.quantity
          : //: data.partidaId.quantity.toFixed(2)
            data.partidaId.quantity
        : 0
      this.updatedAt = data.partidaId.updatedAt
      this.createdAt = data.partidaId.createdAt
      this.code = data.partidaId.code
      this.description = data.partidaId.description
      this.chapterId = data.partidaId.chapterId
      this.name = data.partidaId.name
      this.measures = data.partidaId.measures ? data.partidaId.measures : []
      this.elementInfo = data.elementInfo
        ? Object.keys(data.elementInfo).length > 0
          ? data.elementInfo
          : null
        : null
      this.parentId = data.partidaId.chapterId
      this.unity = data.partidaId.unity ? data.partidaId.unity : ""
      this.status = "OPEN"
      this.updates = data.partidaId.updates ? data.partidaId.updates : null
      this.images = data.partidaId.images ? data.partidaId.images : null
      this.attachedFiles = data.partidaId.attachedFiles
        ? data.partidaId.attachedFiles
        : null
      this.files = data.partidaId.files ? data.partidaId.files : []
      this.comeFrom = data.partidaId.comeFrom ? data.partidaId.comeFrom : null
      this.position = data.partidaId.position ? data.partidaId.position : 0
      this.cert_current = data.partidaId.cert_current
        ? data.partidaId.cert_current
        : 0
      this.cert_previous = data.partidaId.cert_previous
        ? data.partidaId.cert_previous
        : 0
      this.clientPriceWithoutDiscount = data.partidaId
        .clientPriceWithoutDiscount
        ? data.partidaId.clientPriceWithoutDiscount
        : data.partidaId.finalPriceWithoutDiscount
          ? data.partidaId.finalPriceWithoutDiscount
          : 0
      //ONLY FOR FRONTEND
      this.decompositionHiden = data.partidaId._decomposition
        ? data.partidaId._decomposition
        : []
      this.creadaEnFinal = data.creadaEnFinal ? data.creadaEnFinal : false
      this.empty =
        this.code === "" || this.description === "" || this.code === undefined
      this.checked = false
    } catch (error) {
      console.log(error)
    }
  }

  updateChapterId = chapterId => {
    this.chapterId = chapterId
  }
}
export default Part
